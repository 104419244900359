import { ChangeEvent } from "react";
import FormInput from "../../../UI/FormInput";
import TextField from "../../../UI/TextField";
import useAppSelector from "../../../../hooks/useSelector";
import useAppDispatch from "../../../../hooks/useDispatch";
import {
  setContactEmail,
  setContactName,
  setContactPhone,
  setCompany,
  setStreet,
  setCity,
  setZip,
} from "../../../../store/contactSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  loading: boolean;
}

const NewUser = ({ loading }: IProps) => {
  const { t } = useTranslation();
  const name = useAppSelector((state) => state.contact.name);
  const company = useAppSelector((state) => state.contact.company);
  const email = useAppSelector((state) => state.contact.email);
  const phone = useAppSelector((state) => state.contact.phone);
  const street = useAppSelector((state) => state.contact.street);
  const city = useAppSelector((state) => state.contact.city);
  const zip = useAppSelector((state) => state.contact.zip);

  const dispatch = useAppDispatch();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactName(e.target.value));
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactEmail(e.target.value));
  }

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactPhone(e.target.value));
  }

  const handleChangeCompany = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCompany(e.target.value));
  }

  const handleChangeStreet = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setStreet(e.target.value));
  }

  const handleChangeCity = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCity(e.target.value));
  }

  const handleChangeZip = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setZip(e.target.value));
  }

  return (
    <>
      <FormInput
        label={`${t('order.person.title')}*`}
      >
        <TextField
          value={name}
          onChange={handleChangeName}
          placeholder={t('order.person.placeholder')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.company.title')}*`}
      >
        <TextField
          value={company}
          onChange={handleChangeCompany}
          placeholder={`${t('order.company.placeholder')}*`}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label='E-Mail*'
      >
        <TextField
          value={email}
          type='email'
          onChange={handleChangeEmail}
          placeholder='E-Mail'
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.phone')}*`}
      >
        <TextField
          value={phone}
          onChange={handleChangePhone}
          placeholder={t('order.phone')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.street.title')}*`}
      >
        <TextField
          value={street}
          onChange={handleChangeStreet}
          placeholder={t('order.street.placeholder')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.city')}*`}
      >
        <TextField
          value={city}
          onChange={handleChangeCity}
          placeholder={t('order.city')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.postal-code')}*`}
      >
        <TextField
          value={zip}
          onChange={handleChangeZip}
          placeholder={t('order.postal-code')}
          disabled={loading}
        />
      </FormInput>
    </>
  )
};

export default NewUser;