import { IKeyProfiles } from "../constants/pragerohlinge";
import { IKey, IKeySideFont, IKeySideLogoSize, IKeyTextType, IKeyType } from "../store/keysTypes";
import { v4 } from 'uuid';
import { IKeysBlankNames } from "../constants/pragerohlinge";
import getKeyImage from "./getGetKeyImage";

type IKeyTextFields = {
  [name: string]: IKeyTextType[];
}

const KEY_INPUTS: IKeyTextFields = {
  [IKeysBlankNames.FA]: [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
    { name: 'D', value: '', maxLength: 24 },
  ],
  [IKeysBlankNames.OV]: [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
  ],
  'abu-17d': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
    { name: 'D', value: '', maxLength: 24 },
  ],
  'abu-86d': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
    { name: 'D', value: '', maxLength: 24 },
  ],
  'bk-7d': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
    { name: 'D', value: '', maxLength: 24 },
  ],
  'ce-53d': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
  ],
  'wil-66d': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
  ],
  'win-22': [
    { name: 'A', value: '', maxLength: 24 },
    { name: 'B', value: '', maxLength: 24 },
    { name: 'C', value: '', maxLength: 24 },
    { name: 'D', value: '', maxLength: 24 },
  ],
};

export const generateKeyByBlank = (keyType: IKeyProfiles, blank: IKeysBlankNames, defaultKey: IKey): IKey => {
  return {
    ...defaultKey,
    menge: '250',
    id: v4(),
    ...keyType,
    blank,
  }
}

export const generateKey = async (keyType: IKeyProfiles, blank: IKeysBlankNames): Promise<IKey> => {
  const text = [IKeysBlankNames.FA, IKeysBlankNames.OV].includes(blank)
    ? KEY_INPUTS[blank]
    : KEY_INPUTS[keyType.value];

  const menge = [IKeysBlankNames.FA, IKeysBlankNames.OV].includes(blank)
    ? '250'
    : '1000';
  
  const { image } = getKeyImage({ profile: keyType.value, blank });

  const data = await fetch(image);
  const blob = await data.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve({
        ...keyType,
        id: v4(),
        front: {
          type: IKeyType.TEXT,
          font: IKeySideFont.ARIAL,
          logo: '',
          text,
          size: { A: 18, B: 18, C: 18, D: 18 },
          logoSize: IKeySideLogoSize.BIG,
          image: base64data as string,
          ready: false,
        },
        back: {
          type: IKeyType.TEXT,
          font: IKeySideFont.ARIAL,
          logo: '',
          text,
          size: { A: 18, B: 18, C: 18, D: 18 },
          logoSize: IKeySideLogoSize.BIG,
          image: base64data as string,
          ready: false,
        },
        blank,
        menge,
        visited: false,
      });
    };
    reader.onerror = reject;
  });
};

