export default async function resizeImage(file: File) {
  const size = 320;

  const bitmap = await createImageBitmap(file);
  const { width, height } = bitmap;

  const widthDiff = height > width ? height / width : 1;
  const heightDiff = width > height ? width / height : 1;

  const widthRatio = size / width;
  const heightRatio = size / height;

  const imageWidth = width * widthRatio / widthDiff;
  const imageHeight = height * heightRatio / heightDiff;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = imageWidth;
  canvas.height = imageHeight;

  if (ctx) {
    ctx.drawImage(
      bitmap,
      0, 
      0,
      width,
      height,
      0,
      0,
      imageWidth,
      imageHeight,
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png', 1)
    })
  }
}