import React from 'react';
import {Box, styled} from "@mui/material";

const Wrapper = styled(Box)`
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: .2s ease-out;
`;

interface IProps {
  index: number;
  width: number;
}

const ButtonAdornment = ({ index, width }: IProps) => {
  return (
    <Wrapper
      sx={{
        width: width + 'px',
        left: 2 + (index * (width + 1)) + 'px',
      }}
    />
  );
};

export default ButtonAdornment;
