import { IKeySideLogoSize } from "../store/keysTypes";
import { IKeysBlankNames } from "./pragerohlinge";

type IImageCanvasSizes = {
  [blank: string]: {
    [IKeySideLogoSize.SMALL]: {
      width: number;
      height: number;
    };
    [IKeySideLogoSize.MEDIUM]: {
      width: number;
      height: number;
    };
    [IKeySideLogoSize.BIG]: {
      width: number;
      height: number;
    };
  };
};

export const LOGO_CANVAS_SIZES: IImageCanvasSizes = {
  [IKeysBlankNames.FA]: {
    [IKeySideLogoSize.SMALL]: {
      width: 230,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 250,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 270,
      height: 150,
    },
  },
  [IKeysBlankNames.OV]: {
    [IKeySideLogoSize.SMALL]: {
      width: 190,
      height: 80,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 210,
      height: 100,
    },
    [IKeySideLogoSize.BIG]: {
      width: 230,
      height: 120,
    },
  },
  "basi-bmfa": {
    [IKeySideLogoSize.SMALL]: {
      width: 190,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 210,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 230,
      height: 150,
    },
  },
  "basi-vbfa": {
    [IKeySideLogoSize.SMALL]: {
      width: 230,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 250,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 270,
      height: 150,
    },
  },
  "abu-91fa": {
    [IKeySideLogoSize.SMALL]: {
      width: 180,
      height: 100,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 200,
      height: 110,
    },
    [IKeySideLogoSize.BIG]: {
      width: 220,
      height: 120,
    },
  },
  "basi-vfa": {
    [IKeySideLogoSize.SMALL]: {
      width: 230,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 250,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 270,
      height: 150,
    },
  },
  "ci-48fa": {
    [IKeySideLogoSize.SMALL]: {
      width: 280,
      height: 90,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 310,
      height: 110,
    },
    [IKeySideLogoSize.BIG]: {
      width: 340,
      height: 130,
    },
  },
  "is-15fa": {
    [IKeySideLogoSize.SMALL]: {
      width: 150,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 200,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 290,
      height: 150,
    },
  },
  flafa: {
    [IKeySideLogoSize.SMALL]: {
      width: 230,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 250,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 270,
      height: 150,
    },
  },
  "agb-1fa": {
    [IKeySideLogoSize.SMALL]: {
      width: 210,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 230,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 305,
      height: 145,
    },
  },
  "ff-1dfa": {
    [IKeySideLogoSize.SMALL]: {
      width: 210,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 230,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 305,
      height: 145,
    },
  },
  "u-4dfa": {
    [IKeySideLogoSize.SMALL]: {
      width: 210,
      height: 110,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 230,
      height: 130,
    },
    [IKeySideLogoSize.BIG]: {
      width: 305,
      height: 145,
    },
  },
  "abu-17d": {
    [IKeySideLogoSize.SMALL]: {
      width: 210,
      height: 80,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 230,
      height: 100,
    },
    [IKeySideLogoSize.BIG]: {
      width: 250,
      height: 120,
    },
  },
  "abu-86d": {
    [IKeySideLogoSize.SMALL]: {
      width: 200,
      height: 90,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 220,
      height: 110,
    },
    [IKeySideLogoSize.BIG]: {
      width: 240,
      height: 130,
    },
  },
  "bk-7d": {
    [IKeySideLogoSize.SMALL]: {
      width: 200,
      height: 130,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 220,
      height: 150,
    },
    [IKeySideLogoSize.BIG]: {
      width: 240,
      height: 170,
    },
  },
  "ce-53d": {
    [IKeySideLogoSize.SMALL]: {
      width: 240,
      height: 60,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 260,
      height: 80,
    },
    [IKeySideLogoSize.BIG]: {
      width: 280,
      height: 100,
    },
  },
  "wil-66d": {
    [IKeySideLogoSize.SMALL]: {
      width: 180,
      height: 45,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 200,
      height: 65,
    },
    [IKeySideLogoSize.BIG]: {
      width: 220,
      height: 85,
    },
  },
  "win-22": {
    [IKeySideLogoSize.SMALL]: {
      width: 180,
      height: 80,
    },
    [IKeySideLogoSize.MEDIUM]: {
      width: 200,
      height: 100,
    },
    [IKeySideLogoSize.BIG]: {
      width: 220,
      height: 120,
    },
  },
};
