import { styled, Box } from "@mui/material";

export const KeysCardsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;
