import { LanguageValues } from "../constants/language";
import { LanguageStateType } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const searchParams = new URLSearchParams(window.location.search);
const lang = searchParams.get("lang");

const initialState: LanguageStateType = {
  value: lang === "en" || lang === "EN" ? LanguageValues.EN : LanguageValues.DE,
};

export const languageSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
