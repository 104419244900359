type TDecreaseBlank = {
  [name: string]: {
    [name: string]: number;
  };
};

const DECREASE_BY_BLANK: TDecreaseBlank = {
  "basi-bmfa": {
    A: 12,
    B: 12,
    C: 12,
    D: 12,
  },
  "basi-vbfa": {
    A: 10,
    B: 10,
    C: 10,
    D: 10,
  },
  "abu-91fa": {
    A: 12,
    B: 12,
    C: 12,
    D: 13,
  },
  "basi-vfa": {
    A: 10,
    B: 10,
    C: 10,
    D: 10,
  },
  "ci-48fa": {
    A: 40,
    B: 40,
    C: 40,
    D: 40,
  },
  "is-15fa": {
    A: 10,
    B: 10,
    C: 10,
    D: 10,
  },
  flafa: {
    A: 11,
    B: 11,
    C: 11,
    D: 11,
  },
  "agb-1fa": {
    A: 11,
    B: 11,
    C: 11,
    D: 11,
  },
  "ff-1dfa": {
    A: 11,
    B: 11,
    C: 11,
    D: 11,
  },
  "u-4dfa": {
    A: 11,
    B: 11,
    C: 11,
    D: 11,
  },
};

export const getImageSizeByBlank = (
  name: string,
  value: string,
  blank: string
) => {
  if (DECREASE_BY_BLANK?.[blank]?.[name]) {
    return DECREASE_BY_BLANK[blank][name];
  }
  return 10;
};
