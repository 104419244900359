import React, {ReactNode} from 'react';
import {Button, css, styled, SxProps} from "@mui/material";
import {TButtonVariants} from "../../../types/common";
import {SerializedStyles} from "@emotion/react";

const mainCss = css`
  border: 1px solid #06326E;
  background: #06326E;
  color: #FFFFFF;

  &:hover {
    border: 1px solid #E20031;
    background: #E20031;
  }

  &:active {
    border: 1px solid #b50027;
    background: #b50027;
  }
`;

const secondCss = css`
  border: 1px solid #EBEBEB;
  background: white;
  color: #06326E;
  
  &:hover {
    background: #EBEBEB;
  }
  
  &:active {
    border: 1px solid #E2E2E2;
    background: #E2E2E2;
  }
`;

const thirdCss = css`
  border: 1px solid #EBEBEB;
  background: transparent;
  color: #06326E;
  
  &:hover {
    background: #EBEBEB;
  }
  
  &:active {
    border: 1px solid #E2E2E2;
    background: #E2E2E2;
  }
`

const getVariantCss = (variant: TButtonVariants): SerializedStyles => {
  switch (variant) {
    case 'second': return secondCss;
    case 'third': return thirdCss;
    default: return mainCss;
  }
}

interface IButtonProps {
  myvariant: TButtonVariants;
}

const Wrapper = styled(Button)<IButtonProps>`
  border-radius: 8px;
  height: 40px;
  padding: 0 24px;
  text-transform: none;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 21px;
  
  ${props => getVariantCss(props.myvariant)};
  
  &.Mui-disabled {
    border: 1px solid #E2E2E2;
    background: #E2E2E2;
    color: white;
  }
`;

interface IProps {
  children: ReactNode;
  variant?: TButtonVariants;
  onClick?: () => void;
  sx?: SxProps;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const CustomButton = ({
  children,
  variant = 'main',
  onClick,
  sx,
  type = 'button',
  disabled,
}: IProps) => {
  return (
    <Wrapper
      disableRipple
      myvariant={variant}
      onClick={onClick}
      sx={sx}
      type={type}
      disabled={disabled}
    >
      { children }
    </Wrapper>
  );
};

export default CustomButton;
