import { IKeyProfiles, IKeysBlankNames } from "../constants/pragerohlinge";

export enum IKeysModalsCommon {
  MODAL_LOGO = 'modalLogo',
  MODAL_MENGE = 'modalMenge',
  MODAL_DELETE_KEY = 'modalDeleteKey',
}

export enum IKeyType {
  TEXT = 'text',
  LOGO = 'logo',
  BLANKO = 'blanko',
}

export enum IKeySideName {
  FRONT = 'front',
  BACK = 'back',
}

export enum IKeySideFont {
  TIMES = 'times',
  ARIAL = 'arial',
}

export enum IKeySideLogoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export type IKeyTextType = {
  value: string;
  name: string;
  maxLength: number;
}

export type IKeyTextSize = {
  [value: string]: number;
}

export type IKeySide = {
  text: IKeyTextType[];
  logo: string;
  type: IKeyType;
  font: IKeySideFont;
  size: IKeyTextSize;
  logoSize: IKeySideLogoSize;
  image: string;
  ready: boolean;
}

export type TSelectedKeyTypes = {
  [IKeysBlankNames.FA]: IKey[],
  [IKeysBlankNames.OV]: IKey[],
  [IKeysBlankNames.ORIGINAL]: IKey[],
}

export interface IKey extends IKeyProfiles {
  front: IKeySide;
  back: IKeySide;
  id: string;
  visited: boolean;
  menge: string;
  blank: IKeysBlankNames;
}

export type AppKeysStateType = {
  page: number;
  enabledTabIds: number[];
  selectedKeyTypes: TSelectedKeyTypes;
  selectedKeyId: string|null;
  modalName: IKeysModalsCommon|null;
  mengeModalType: string|null;
  comment: string;
  deletionId: string|null;
};
