import { styled } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { ChangeEvent } from "react";

export interface ITextareaProps {
  value: number | string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
}

const Wrapper = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    padding: 8px;
    text-align: left;
    resize: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    &:focus,
    &:focus-visible {
      border: 1px solid #06326E;
      outline: 1px solid #06326E;
      color: #06326E;
    }
    
    &::placeholder {
      opacity: 0.42;
  }
  `
)

const TextArea = ({
  value,
  onChange,
  maxRows,
  minRows,
  placeholder = 'Kommentar',
}: ITextareaProps) => {
  return (
    <Wrapper
      value={value}
      minRows={minRows || 5}
      maxRows={maxRows || 10}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
};

export default TextArea;