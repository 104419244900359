import {instance} from "./instance";
import {AxiosResponse, AxiosRequestConfig} from "axios";

export const httpClient = {
  post(urn: string, data?: unknown, params?: AxiosRequestConfig): Promise<AxiosResponse<unknown, unknown>> {
    return instance.post(urn, data, params);
  },
  get(urn: string): Promise<AxiosResponse<unknown, unknown>> {
    return instance.get(urn);
  },
}
