import { Box, css, styled, Tab, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #e0e0e0;
  overflow: unset;

  .MuiTabs-scroller {
    overflow: unset !important;
  }

  .MuiTabs-indicator {
    background-color: #e20031;
    height: 2px;
  }
`;

export const StyledTab = styled(Tab)<{ isActive: boolean }>`
  text-transform: none;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06326e;
  padding: 16px;
  border-bottom: 2px solid transparent;
  height: 56px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: ${({ isActive }) => (isActive ? "inherit" : "none")};
  }

  &.Mui-selected {
    color: #e20031;
  }

  &:hover {
    border-bottom: 2px solid #e20031;
    color: #e20031;

    .tab-styled-num {
      color: #e20031;
    }
  }
`;

interface StyledNumProps {
  isactive: string;
  disabled: boolean;
}

const getColor = (props: StyledNumProps): string => {
  if (props.disabled) {
    return "#989898";
  }

  if (props.isactive === "true") {
    return "white";
  }

  return "#06326E";
};

export const StyledNum = styled(Box)<StyledNumProps>`
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.isactive === "true" ? "#E20031" : "transparent"};
  border-radius: 50%;
  color: ${(props) => getColor(props)};

  ${(props) =>
    props.isactive === "true" &&
    css`
      color: white !important;
    `}
`;
