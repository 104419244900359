import { IKeyProfiles, IKeysProduktCard } from "../../../../constants/pragerohlinge";
import {Box, styled} from "@mui/material";
// import imgCircle from '../../../../assets/img/circle.svg';
// import imgCircleActive from '../../../../assets/img/circleActive.svg';
import { ReactNode, useState } from "react";
import TextCardTitle from "../../../UI/Typography/TextCardTitle";
import Select from "../../../UI/Select";
import { useTranslation } from "react-i18next";
// import ImageSlider from "../../../UI/ImageSlider/ImageSlider";

interface WrapperProps {
  isselected: string;
  ishovered: string;
}

const Wrapper = styled(Box)`
  width: 100%;
`;

const CardBox = styled(Box)<WrapperProps>`
  width: 100%;
  height: 450px;
  background: #FFFFFF;
  border-radius: 16px;
  border: 2px solid ${props => props.isselected === 'true' ? '#E20031' : props.ishovered === 'true' ? 'rgba(226, 0, 49, 0.5)' : 'white'};
  cursor: pointer;
  padding: 8px;
  position: relative;
  margin-bottom: 8px;
  transition: border .2s ease-in;
`;

// const CircleBox = styled(Box)`
//   width: 24px;
//   height: 24px;
//   position: absolute;
//   left: 8px;
//   top: 8px;
// `;

interface WrapperImageBox {
  src: string;
}

const ImageBox = styled(Box)<WrapperImageBox>`
  width: 100%;
  height: 224px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const ItemsRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

interface WrapperSelectedItem {
  children: ReactNode;
}

const SelectedItem = styled(Box)<WrapperSelectedItem>`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 24px;
  border: 2px solid #E20031;
  padding: 0 16px;
  font-size: 16px;
  color: #06326E;
`;

const SelectedItemDelete = styled(Box)`
  position: relative;
  margin-left: 8px;
  width: 16px;
  height: 16px;

  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 1.5px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    background-color: #06326E;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 1.5px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background-color: #06326E;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const SelectedAmount = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #06326E;
`;

interface IProps {
  data: IKeysProduktCard;
  isSelected: boolean;
  keysTypes: IKeyProfiles[];
  selectedKeyTypes: IKeyProfiles[];
  onChangeSelect: (value: IKeyProfiles|undefined) => void;
  onRemoveItem: (value: IKeyProfiles|undefined) => void;
}

const KeysProductCard = ({
  data,
  isSelected,
  keysTypes,
  selectedKeyTypes,
  onChangeSelect,
  onRemoveItem,
}: IProps) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  // const getCircleImg = () => {
  //   if (isSelected) {
  //     return imgCircleActive;
  //   }

  //   return imgCircle;
  // };

  const handleChangeSelect = (value: string|[]) => {
    if (typeof value === 'object') {
      value = value.find(item => !selectedKeyTypes.includes(item) && item !== t('keys.placeholder')) || '';
    }
    onChangeSelect(keysTypes.find((item: IKeyProfiles) => item.value === value));
  };

  const handleRemoveIconClick = (value: string) => {
    onRemoveItem(keysTypes.find((item: IKeyProfiles) => item.value === value));
  };

  return (
    <Wrapper>
      <CardBox
        ishovered={String(hover)}
        isselected={String(isSelected)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* <CircleBox
          sx={{ zIndex: '100' }}
        >
          <img
            src={getCircleImg()}
            alt=''
          />
        </CircleBox> */}
        <ImageBox
          src={data.img[0]}
        />
        {/* <ImageSlider
          images={data.img}
        /> */}
        <Box sx={{ margin: '25px 0 20px' }}>
          <TextCardTitle
            sx={{ textAlign: 'center' }}
          >
            { t(`keys.${data.title}`) }
          </TextCardTitle>
        </Box>
        <Box sx={{ margin: '8px 0' }}>
          <Select
            options={keysTypes}
            value={[t('keys.placeholder')]}
            placeholder={t('keys.placeholder')}
            multipleValues={selectedKeyTypes}
            multiple={true}
            onChange={(e) => handleChangeSelect(e.target.value as string)}
          />
        </Box>
        {
          isSelected &&
          <ItemsRow>
            {selectedKeyTypes.slice(0, 4).map((item) => 
              <SelectedItem key={item.value}>
                <>
                  <Box sx={{ paddingTop: '5px' }}>
                    {item.name}
                  </Box>
                  <SelectedItemDelete onClick={() => handleRemoveIconClick(item.value)}/>
                </>
              </SelectedItem>
            )}
            {
              selectedKeyTypes.length > 4 &&
              <SelectedAmount>
                +{selectedKeyTypes.slice(4).length}
              </SelectedAmount>
            }
          </ItemsRow>
        }
      </CardBox>
    </Wrapper>
  );
};

export default KeysProductCard;