import { Box, styled } from "@mui/material";

export const FileLoaderErrorText = styled(Box)`
  margin-left: auto;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #E20031;
`

export const FileLoaderText = styled(Box)`
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  color: #06326E;
  text-transform: unset;
`

export const FileLoaderReadyWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FileLoaderImage = styled(Box)`
  height: 40px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 24px;
  }
`;

export const FileLoaderControls = styled(Box)`
  display: flex;
  align-items: center
`