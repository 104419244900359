import {styled, Box} from "@mui/material";
import Button from "../../UI/Button";
import {TTabsData} from "../../../constants/common";
import svgNext from '../../../assets/img/arrowRight.svg';
import AppLink from '../../UI/Link/AppLink';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 16px;
  left: 0;
`;

const ButtonBox = styled(Box)`
  position: absolute;
  bottom: 0;
`;

interface IProps {
  link?: string;
  tabsData: TTabsData[];
  page: number;
  onBack: () => void;
  onForward: () => void;
  onDownloadPdf?: () => void;
  disablePagination: boolean;
  disableForward: boolean;
}

const Paginator = ({
  link,
  tabsData,
  page,
  onBack,
  onForward,
  onDownloadPdf,
  disablePagination,
  disableForward,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {
        page > 1 &&
          <ButtonBox
            sx={{
              left: '16px',
            }}
          >
            <Button
              variant='second'
              onClick={onBack}
              disabled={disablePagination}
            >
              {t('pagination.back')}
            </Button>
          </ButtonBox>
      }
      {
        link &&
        <ButtonBox
          sx={{
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <AppLink
            onClick={onDownloadPdf}
          >
            {link}
          </AppLink>
        </ButtonBox>
      }
      {
        page < tabsData.length &&
          <ButtonBox
            sx={{
              right: '16px',
            }}
          >
            <Button
              onClick={onForward}
              disabled={disablePagination || disableForward}
            >
              {t('pagination.next')}
              <img
                src={svgNext}
                alt=''
              />
            </Button>
          </ButtonBox>
      }
    </Wrapper>
  );
};

export default Paginator;
