import React from 'react';

const CircleWithCheck = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" transform="matrix(1 0 0 -1 0 24)"/>
    <path d="M6.5 11.5L10.5 15.5L17.5 8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export default CircleWithCheck;
