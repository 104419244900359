import React, { ReactNode } from "react";
import { styled, Box } from "@mui/material";
import ButtonClose from "../ButtonClose";
import { SCROLL_CSS } from "../../../constants/common";

interface WrapperProps {
  width: string;
}

const Wrapper = styled(Box)<WrapperProps>`
  background: white;
  border-radius: 24px;
  padding: 40px;
  position: relative;
  width: ${(props) => props.width};
  overflow: auto;

  ${SCROLL_CSS};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: unset;
  }
`;

const CloseBox = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface IProps {
  children: ReactNode;
  onClose: () => void;
  width?: string;
  maxWidth?: string;
}

const Modal = ({ children, onClose, width, maxWidth }: IProps) => {
  return (
    <Wrapper width={width || "480px"} maxWidth={maxWidth}>
      {children}
      <CloseBox>
        <ButtonClose onClick={onClose} />
      </CloseBox>
    </Wrapper>
  );
};

export default Modal;
