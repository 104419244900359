import {styled, Typography} from "@mui/material";

const TextLabel = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
`;

export default TextLabel;
