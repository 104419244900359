import { IKeysBlankNames } from "./pragerohlinge";

type TTextContainerPositions = {
  [blank: string]: {
    defaultBack?: {
      [name: string]: {
        x: number;
        y: number;
      };
    };
    zoomBack?: {
      [name: string]: {
        x: number;
        y: number;
      };
    };
    default: {
      [name: string]: {
        x: number;
        y: number;
      };
    };
    zoom: {
      [name: string]: {
        x: number;
        y: number;
      };
    };
  };
};

export const TEXT_CONTAINER_POSITIONS: TTextContainerPositions = {
  [IKeysBlankNames.FA]: {
    default: {
      A: {
        x: 210,
        y: 85,
      },
      B: {
        x: 210,
        y: 100,
      },
      C: {
        x: 210,
        y: 115,
      },
      D: {
        x: 210,
        y: 130,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 170,
      },
      B: {
        x: 225,
        y: 200,
      },
      C: {
        x: 225,
        y: 230,
      },
      D: {
        x: 225,
        y: 260,
      },
    },
  },
  [IKeysBlankNames.OV]: {
    default: {
      A: {
        x: 196,
        y: 85,
      },
      B: {
        x: 196,
        y: 105,
      },
      C: {
        x: 196,
        y: 125,
      },
    },
    zoom: {
      A: {
        x: 198,
        y: 170,
      },
      B: {
        x: 198,
        y: 210,
      },
      C: {
        x: 198,
        y: 250,
      },
    },
  },
  "basi-bmfa": {
    default: {
      A: {
        x: 210,
        y: 65,
      },
      B: {
        x: 210,
        y: 80,
      },
      C: {
        x: 210,
        y: 95,
      },
      D: {
        x: 210,
        y: 110,
      },
    },
    zoom: {
      A: {
        x: 230,
        y: 130,
      },
      B: {
        x: 230,
        y: 160,
      },
      C: {
        x: 230,
        y: 190,
      },
      D: {
        x: 230,
        y: 220,
      },
    },
  },
  "basi-vbfa": {
    default: {
      A: {
        x: 210,
        y: 85,
      },
      B: {
        x: 210,
        y: 100,
      },
      C: {
        x: 210,
        y: 115,
      },
      D: {
        x: 210,
        y: 130,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 170,
      },
      B: {
        x: 225,
        y: 200,
      },
      C: {
        x: 225,
        y: 230,
      },
      D: {
        x: 225,
        y: 260,
      },
    },
  },
  "abu-91fa": {
    default: {
      A: {
        x: 210,
        y: 65,
      },
      B: {
        x: 210,
        y: 80,
      },
      C: {
        x: 210,
        y: 95,
      },
      D: {
        x: 210,
        y: 110,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 130,
      },
      B: {
        x: 225,
        y: 160,
      },
      C: {
        x: 225,
        y: 190,
      },
      D: {
        x: 225,
        y: 220,
      },
    },
  },
  "basi-vfa": {
    default: {
      A: {
        x: 210,
        y: 85,
      },
      B: {
        x: 210,
        y: 100,
      },
      C: {
        x: 210,
        y: 115,
      },
      D: {
        x: 210,
        y: 130,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 170,
      },
      B: {
        x: 225,
        y: 200,
      },
      C: {
        x: 225,
        y: 230,
      },
      D: {
        x: 225,
        y: 260,
      },
    },
  },
  "ci-48fa": {
    default: {
      A: {
        x: 210,
        y: 75,
      },
      B: {
        x: 210,
        y: 90,
      },
      C: {
        x: 210,
        y: 105,
      },
      D: {
        x: 210,
        y: 120,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 155,
      },
      B: {
        x: 225,
        y: 185,
      },
      C: {
        x: 225,
        y: 215,
      },
      D: {
        x: 225,
        y: 245,
      },
    },
  },
  "is-15fa": {
    default: {
      A: {
        x: 190,
        y: 90,
      },
      B: {
        x: 190,
        y: 108,
      },
      C: {
        x: 190,
        y: 126,
      },
      D: {
        x: 190,
        y: 144,
      },
    },
    defaultBack: {
      A: {
        x: 198,
        y: 112,
      },
      B: {
        x: 198,
        y: 127,
      },
      C: {
        x: 198,
        y: 142,
      },
      D: {
        x: 198,
        y: 157,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 170,
      },
      B: {
        x: 225,
        y: 200,
      },
      C: {
        x: 225,
        y: 230,
      },
      D: {
        x: 225,
        y: 260,
      },
    },
  },
  flafa: {
    default: {
      A: {
        x: 210,
        y: 85,
      },
      B: {
        x: 210,
        y: 100,
      },
      C: {
        x: 210,
        y: 115,
      },
      D: {
        x: 210,
        y: 130,
      },
    },
    zoom: {
      A: {
        x: 225,
        y: 170,
      },
      B: {
        x: 225,
        y: 200,
      },
      C: {
        x: 225,
        y: 230,
      },
      D: {
        x: 225,
        y: 260,
      },
    },
  },
  "agb-1fa": {
    default: {
      A: {
        x: 193,
        y: 74,
      },
      B: {
        x: 193,
        y: 89,
      },
      C: {
        x: 193,
        y: 104,
      },
      D: {
        x: 193,
        y: 119,
      },
    },
    zoom: {
      A: {
        x: 193,
        y: 150,
      },
      B: {
        x: 193,
        y: 180,
      },
      C: {
        x: 193,
        y: 210,
      },
      D: {
        x: 193,
        y: 240,
      },
    },
  },
  "ff-1dfa": {
    default: {
      A: {
        x: 193,
        y: 74,
      },
      B: {
        x: 193,
        y: 89,
      },
      C: {
        x: 193,
        y: 104,
      },
      D: {
        x: 193,
        y: 119,
      },
    },
    zoom: {
      A: {
        x: 193,
        y: 150,
      },
      B: {
        x: 193,
        y: 180,
      },
      C: {
        x: 193,
        y: 210,
      },
      D: {
        x: 193,
        y: 240,
      },
    },
  },
  "u-4dfa": {
    default: {
      A: {
        x: 193,
        y: 74,
      },
      B: {
        x: 193,
        y: 89,
      },
      C: {
        x: 193,
        y: 104,
      },
      D: {
        x: 193,
        y: 119,
      },
    },
    zoom: {
      A: {
        x: 193,
        y: 150,
      },
      B: {
        x: 193,
        y: 180,
      },
      C: {
        x: 193,
        y: 210,
      },
      D: {
        x: 193,
        y: 240,
      },
    },
  },
  "abu-17d": {
    defaultBack: {
      A: {
        x: 194,
        y: 95,
      },
      B: {
        x: 194,
        y: 110,
      },
      C: {
        x: 194,
        y: 125,
      },
      D: {
        x: 194,
        y: 140,
      },
    },
    zoomBack: {
      A: {
        x: 195,
        y: 190,
      },
      B: {
        x: 195,
        y: 215,
      },
      C: {
        x: 195,
        y: 240,
      },
      D: {
        x: 195,
        y: 265,
      },
    },
    default: {
      A: {
        x: 190,
        y: 95,
      },
      B: {
        x: 190,
        y: 110,
      },
      C: {
        x: 190,
        y: 125,
      },
      D: {
        x: 190,
        y: 140,
      },
    },
    zoom: {
      A: {
        x: 195,
        y: 190,
      },
      B: {
        x: 195,
        y: 215,
      },
      C: {
        x: 195,
        y: 240,
      },
      D: {
        x: 195,
        y: 265,
      },
    },
  },
  "abu-86d": {
    default: {
      A: {
        x: 200,
        y: 90,
      },
      B: {
        x: 200,
        y: 105,
      },
      C: {
        x: 200,
        y: 120,
      },
      D: {
        x: 200,
        y: 135,
      },
    },
    zoom: {
      A: {
        x: 193,
        y: 180,
      },
      B: {
        x: 193,
        y: 210,
      },
      C: {
        x: 193,
        y: 240,
      },
      D: {
        x: 193,
        y: 270,
      },
    },
  },
  "bk-7d": {
    default: {
      A: {
        x: 189,
        y: 90,
      },
      B: {
        x: 189,
        y: 106,
      },
      C: {
        x: 189,
        y: 122,
      },
      D: {
        x: 189,
        y: 138,
      },
      E: {
        x: 189,
        y: 154,
      },
    },
    zoom: {
      A: {
        x: 192,
        y: 180,
      },
      B: {
        x: 192,
        y: 215,
      },
      C: {
        x: 192,
        y: 250,
      },
      D: {
        x: 192,
        y: 285,
      },
      E: {
        x: 192,
        y: 220,
      },
    },
  },
  "ce-53d": {
    default: {
      A: {
        x: 191,
        y: 100,
      },
      B: {
        x: 191,
        y: 120,
      },
    },
    zoom: {
      A: {
        x: 190,
        y: 210,
      },
      B: {
        x: 190,
        y: 240,
      },
    },
  },
  "wil-66d": {
    default: {
      A: {
        x: 195,
        y: 95,
      },
      B: {
        x: 195,
        y: 110,
      },
      C: {
        x: 195,
        y: 125,
      },
    },
    zoom: {
      A: {
        x: 190,
        y: 190,
      },
      B: {
        x: 190,
        y: 220,
      },
      C: {
        x: 190,
        y: 250,
      },
    },
  },
  "win-22": {
    default: {
      A: {
        x: 193,
        y: 74,
      },
      B: {
        x: 193,
        y: 89,
      },
      C: {
        x: 193,
        y: 104,
      },
      D: {
        x: 193,
        y: 119,
      },
    },
    zoom: {
      A: {
        x: 193,
        y: 150,
      },
      B: {
        x: 193,
        y: 180,
      },
      C: {
        x: 193,
        y: 210,
      },
      D: {
        x: 193,
        y: 240,
      },
    },
  },
};
