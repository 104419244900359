import { ISwitchButtonsOptions } from "../components/UI/SwitchButtons/SwitchButtons";
import { IKeySideFont, IKeySideLogoSize } from "../store/keysTypes";

export const KEY_ENGRAVING_TYPES: ISwitchButtonsOptions[] = [
  {
    name: 'Text',
    value: 'text',
  },
  {
    name: 'Logo',
    value: 'logo',
  },
  {
    name: 'Blanko',
    value: 'blanko',
  },
];

type IKeyFontType = {
  value: string;
  name: string;
}

export const KEY_FONT_TYPES: IKeyFontType[] = [
  {
    name: 'Arial',
    value: IKeySideFont.ARIAL,
  },
  {
    name: 'Times New Roman',
    value: IKeySideFont.TIMES,
  }
];

type IKeySideLogoSizeType = {
  name: string;
  value: IKeySideLogoSize;
}

export const KEY_LOGO_SIZES: IKeySideLogoSizeType[] = [
  {
    name: 'Klein',
    value: IKeySideLogoSize.SMALL,
  },
  {
    name: 'Mittel',
    value: IKeySideLogoSize.MEDIUM,
  },
  {
    name: 'Groß',
    value: IKeySideLogoSize.BIG,
  }
];