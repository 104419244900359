import "@pixi/extract";
import { Stage, Sprite, Text, Container } from "@pixi/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Application, ColorMatrixFilter } from "pixi.js";
import * as PIXI from "pixi.js";
import useImageFrameProps from "../../../hooks/useImageFrameProps";
import {
  IKeySideLogoSize,
  IKeySideName,
  IKeyTextSize,
  IKeyTextType,
  IKeyType,
} from "../../../store/keysTypes";
import { ColorReplaceFilter } from "@pixi/filter-color-replace";
// import {OutlineFilter} from '@pixi/filter-outline';
import { DropShadowFilter } from "@pixi/filter-drop-shadow";
import { getImageSizeByBlank } from "../../../tools/getImageSizeByBlank";
import { IKeysBlankNames } from "../../../constants/pragerohlinge";

const appWidth = 385;
const appHeight = 400;

const ImageBlackAndWhite = new ColorMatrixFilter();
ImageBlackAndWhite.blackAndWhite(false);

// const ImageContrast = new ColorMatrixFilter();
// ImageContrast.brightness(0., false)

// const Outline = new OutlineFilter(1.2, 0x808080, 1, 1, false);

const ColorReplace = new ColorReplaceFilter(0x000000, 0xe3e3e3, 0);
const WhiteColorReplace = new ColorReplaceFilter(0xffffff, 0xcccccc, 0);

const DropShadow = new DropShadowFilter({
  color: 0x949494,
  offset: { x: 0, y: 0 },
  alpha: 1,
  blur: 1,
  quality: 1,
  pixelSize: 1.2,
});

interface IProps {
  type: IKeyType;
  image: string;
  focus?: boolean;
  text: IKeyTextType[];
  blank: string;
  logo: string;
  font: string;
  size: IKeyTextSize;
  logoSize: IKeySideLogoSize;
  side: IKeySideName;
  onScreenshot: (value: string) => void;
}

const ImageFrame = ({
  type,
  image,
  focus = false,
  text,
  logo,
  font,
  size,
  logoSize,
  side,
  onScreenshot,
  blank,
}: IProps) => {
  const [app, setApp] = useState<Application>();
  const containerRef = useRef(null);

  const {
    getFont,
    getLogoSize,
    getLogoXPosition,
    getLogoYPosition,
    getImageHeight,
    getImageWidth,
    getImageX,
    getTextContainerXPosition,
    getTextContainerYPosition,
    getTextXPosition,
    getTextMask,
  } = useImageFrameProps({
    font,
    logoSize,
    focus: focus,
    logo,
    blank,
    side,
    type,
  });

  async function takeScreenshot() {
    if (app?.renderer?.extract && containerRef.current) {
      return await app.renderer.extract.base64(containerRef.current);
    }
  }

  const getFontSize = useMemo(() => {
    return (name: string, value: string) => {
      if (focus) {
        return size[name] + 2;
      }

      return size[name] - getImageSizeByBlank(name, value, blank);
    };
  }, [focus, size]);

  const getImageScale = useMemo(() => {
    if ((focus || type === IKeyType.LOGO) && side === IKeySideName.FRONT) {
      return { x: 1, y: 1 };
    }

    if ((focus || type === IKeyType.LOGO) && side === IKeySideName.BACK) {
      return { x: -1, y: 1 };
    }

    if (side === IKeySideName.BACK) {
      return { x: -0.5, y: 0.5 };
    }
    return { x: 0.5, y: 0.5 };
  }, [side, focus, type]);

  const getImageAnchor = useMemo(() => {
    if (side === IKeySideName.BACK) {
      return { x: 1, y: 0 };
    }
    return { x: 0, y: 0 };
  }, [side]);

  useEffect(() => {
    setTimeout(() => {
      if (app) {
        takeScreenshot().then((data) => {
          if (data) {
            onScreenshot(data);
          }
        });
      }
    }, 300);
  }, [app, image, text, logo, font, size, logoSize, type, focus]);

  return (
    <Stage
      width={appWidth}
      height={appHeight}
      options={{ backgroundColor: 0xffffff }}
      onMount={setApp}
    >
      <Container ref={containerRef}>
        <Sprite
          image={image}
          width={getImageWidth}
          height={getImageHeight}
          anchor={getImageAnchor}
          scale={getImageScale}
          x={getImageX}
          y={0}
        />
        {type === IKeyType.TEXT &&
          text.map((item, index) => (
            <Container
              key={item.name}
              x={getTextContainerXPosition(item.name)}
              y={getTextContainerYPosition(item.name)}
              mask={getTextMask(item.name)}
            >
              <Text
                x={getTextXPosition(item.name)}
                y={0}
                anchor={0.5}
                resolution={5}
                text={item.value}
                filters={[ImageBlackAndWhite]}
                style={
                  new PIXI.TextStyle({
                    align: "right",
                    fontSize: getFontSize(item.name, item.value),
                    fontFamily: getFont,
                    fill: ["#fff"],
                    dropShadow: true,
                    dropShadowColor: "#666",
                    dropShadowDistance: 2,
                    dropShadowBlur: 1.5,
                  })
                }
              />
            </Container>
          ))}
        {type === IKeyType.LOGO && logo && (
          <Sprite
            image={logo}
            width={getLogoSize.width}
            height={getLogoSize.height}
            anchor={0.5}
            x={getLogoXPosition}
            y={getLogoYPosition}
            filters={[
              ImageBlackAndWhite,
              ColorReplace,
              WhiteColorReplace,
              DropShadow,
            ]}
          />
        )}
      </Container>
    </Stage>
  );
};

export default ImageFrame;
