import React from 'react';
import {Checkbox, styled} from "@mui/material";
import icon from '../../../assets/img/checkbox.svg';
import iconHover from '../../../assets/img/checkboxHover.svg';
import iconChecked from '../../../assets/img/checkboxSelected.svg';

const Wrapper = styled(Checkbox)`
  .MuiButtonBase-root {
    background: #FFFFFF;
    border: 2px solid #BDBDBD;
    border-radius: 4px;
  }
`;

interface IProps {
  myhover: string;
  [key: string]: unknown;
}

const CheckboxElement = ({ myhover, ...other }: IProps) => {
  return (
    <Wrapper
      disableRipple
      icon={<img
        src={myhover === 'true' ? iconHover : icon}
        alt='-'
      />}
      checkedIcon={<img
        src={iconChecked}
        alt='+'
      />}
      {...other}
    />
  );
};

export default CheckboxElement;
