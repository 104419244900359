import TextLabelBlue from "../../../UI/Typography/TextLabelBlue";
import { Box, styled } from "@mui/material";
import { SCROLL_CSS } from "../../../../constants/common";
import useAppSelector from "../../../../hooks/useSelector";
import Card from "../../../UI/Card";
import useAppDispatch from "../../../../hooks/useDispatch";
import {
  setDeletionId,
  setKeysModalName,
  setSelectedKeyId,
  setVisited,
} from "../../../../store/appKeysSlice";
import { useEffect, useMemo } from "react";
import { IKey, IKeysModalsCommon } from "../../../../store/keysTypes";
import Button from "../../../UI/Button";
import { useTranslation } from "react-i18next";
import { IKeysBlankNames } from "../../../../constants/pragerohlinge";
import { PragungSelectorProfiles } from "../styles";
import TextCardTitle from "../../../UI/Typography/TextCardTitle";
import useKeysPaginator from "../../../../hooks/useKeysPaginator";

const Wrapper = styled(Box)`
  padding-top 5px;
  min-height: 360px;
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
  padding-top: 5px;

  .draggable-item {
    display: flex;
  }

  .draggable-item__drag {
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
  }

  ${SCROLL_CSS};
`;

const PragungSelector = () => {
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const selectedKeyId = useAppSelector((state) => state.appKeys.selectedKeyId);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { onChangeTab } = useKeysPaginator();

  const selectedKeysArray = useMemo(() => {
    return Object.values(keys).flat();
  }, [keys]);

  useEffect(() => {
    if (!selectedKeyId && selectedKeysArray.length) {
      const selectedKey = selectedKeysArray[0];
      const { blank } = selectedKey;
      dispatch(setSelectedKeyId(selectedKeysArray[0].id));
      if (blank === IKeysBlankNames.FA || blank === IKeysBlankNames.OV) {
        selectedKeysArray.forEach((item, index) => {
          if (item.blank === blank) {
            dispatch(
              setVisited({
                keyIndex: index,
                visited: true,
                blank: item.blank,
              })
            );
          }
        });
        return;
      }
      dispatch(
        setVisited({
          keyIndex: 0,
          visited: true,
          blank: selectedKeysArray[0].blank,
        })
      );
    } else if (
      !selectedKeysArray.find((item) => item.id === selectedKeyId) &&
      selectedKeysArray.length
    ) {
      console.log(selectedKeysArray);
      const nextUnvisitedKey =
        selectedKeysArray.find((item) => !item.visited) ||
        selectedKeysArray[selectedKeysArray.length - 1];
      const { blank: nextUnvisitedKeyBlank, id } = nextUnvisitedKey;
      const nextUnvisitedKeyIndex = keys[nextUnvisitedKeyBlank].findIndex(
        (item) => !item.visited
      );
      dispatch(setSelectedKeyId(id));
      dispatch(
        setVisited({
          keyIndex: nextUnvisitedKeyIndex,
          visited: true,
          blank: nextUnvisitedKeyBlank,
        })
      );
    } else if (!selectedKeysArray.length) {
      onChangeTab(1);
      dispatch(setSelectedKeyId(null));
    }
  }, [selectedKeysArray, selectedKeyId]);

  const handleKeySelect = (id: string) => {
    dispatch(setSelectedKeyId(id));
    const blank = selectedKeysArray.find((key) => key.id === id)?.blank;
    if (blank === IKeysBlankNames.FA || blank === IKeysBlankNames.OV) {
      keys[blank].forEach((item, index) => {
        if (item.blank === blank) {
          dispatch(
            setVisited({
              keyIndex: index,
              visited: true,
              blank,
            })
          );
        }
      });
      return;
    }
    if (blank) {
      dispatch(
        setVisited({
          keyIndex: keys[blank].findIndex((key) => key.id === id),
          visited: true,
          blank,
        })
      );
    }
  };

  const handleRemoveKey = (id: string) => {
    dispatch(setDeletionId(id));
    dispatch(setKeysModalName(IKeysModalsCommon.MODAL_DELETE_KEY));
  };

  const handleAddKey = () => {
    onChangeTab(1);
  };

  const handleSectionClick = (blank: string, value: IKey[]) => {
    if (blank !== IKeysBlankNames.ORIGINAL && value.length) {
      handleKeySelect(value[0].id);
    }
  };

  const isSectionSelected = useMemo(() => {
    return (blank: string, value: IKey[]) =>
      value.some((key) => key.id === selectedKeyId) &&
      blank !== IKeysBlankNames.ORIGINAL;
  }, [keys, selectedKeyId]);

  const isShowSectionHover = useMemo(() => {
    return (blank: string) => blank !== IKeysBlankNames.ORIGINAL;
  }, [keys]);

  return (
    <Wrapper>
      <TextLabelBlue sx={{ marginBottom: "16px" }}>
        {t("embossing.profile-title")}
      </TextLabelBlue>

      <Box>
        {Object.entries(keys)
          .filter(([blank, value]) => value.length)
          .map(([blank, value]) => (
            <Box
              key={blank}
              sx={{ ":not(:last-child)": { marginBottom: "24px" } }}
            >
              <PragungSelectorProfiles
                isselected={isSectionSelected(blank, value).toString()}
                showhover={isShowSectionHover(blank).toString()}
                onClick={() => handleSectionClick(blank, value)}
              >
                <TextCardTitle sx={{ marginBottom: "8px", fontWeight: "400" }}>
                  {t(`keys.${blank}`)}
                </TextCardTitle>
                <Box>
                  {value.map((key) => (
                    <Card
                      title={key.name}
                      key={key.value}
                      isSelected={
                        blank !== IKeysBlankNames.ORIGINAL
                          ? isSectionSelected(blank, value)
                          : key.id === selectedKeyId
                      }
                      isVisited={key.visited}
                      isEntered={false}
                      image={
                        key.value === "flafa"
                          ? ""
                          : `${
                              process.env.PUBLIC_URL
                            }/black-key-profiles/${key.value.toUpperCase()}.jpg`
                      }
                      clickable={blank === IKeysBlankNames.ORIGINAL}
                      onClick={() => handleKeySelect(key.id)}
                      onClickRemove={() => handleRemoveKey(key.id)}
                      id={`keyId_${key.id}`}
                    />
                  ))}
                </Box>
              </PragungSelectorProfiles>

              <Button
                variant="third"
                sx={{ width: "100%" }}
                onClick={handleAddKey}
              >
                {t("embossing.add-button")}
              </Button>
            </Box>
          ))}
      </Box>
    </Wrapper>
  );
};

export default PragungSelector;
