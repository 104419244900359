import React from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import TextSubheading from '../UI/Typography/TextSubheading';
import TextRegularGrey from "../UI/Typography/TextRegularGrey";
import { useTranslation } from 'react-i18next';

const ModalLogo = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      maxWidth='480px'
      width='100%'
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        {t('modal.key-logo.title')}
      </TextModalHeading>

      <TextSubheading sx={{ marginBottom: '8px' }}>
        {t('modal.key-logo.format.title')}
      </TextSubheading>
      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t('modal.key-logo.format.text')}
      </TextRegularGrey>

      <TextSubheading sx={{ marginBottom: '8px' }}>
        {t('modal.key-logo.size.title')}
      </TextSubheading>
      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t('modal.key-logo.size.text')}
      </TextRegularGrey>

      <TextSubheading sx={{ marginBottom: '8px' }}>
        {t('modal.key-logo.quality.title')}
      </TextSubheading>
      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t('modal.key-logo.quality.text')}
      </TextRegularGrey>

      <Button
        variant='second'
        sx={{
          marginTop: '32px',
        }}
        onClick={onClose}
      >
        {t('modal.close')}
      </Button>
    </Modal>
  );
};

export default ModalLogo;