type IMengeRange = {
  value: string;
  name: string;
}

export const MENGE_RANGES: IMengeRange[] = [
  {
    value: '1000',
    name: '1000',
  },
  {
    value: '1250',
    name: '1250',
  },
  {
    value: '1500',
    name: '1500',
  },
  {
    value: '1750',
    name: '1750',
  },
  {
    value: '2000',
    name: '2000',
  },
  {
    value: '2250',
    name: '2250',
  },
  {
    value: '2500',
    name: '2500',
  },
  {
    value: '2750',
    name: '2750',
  },
  {
    value: '3000',
    name: '3000',
  },
  {
    value: '3250',
    name: '3250',
  },
  {
    value: '3500',
    name: '3500',
  },
  {
    value: '3750',
    name: '3750',
  },
  {
    value: '4000',
    name: '4000',
  },
  {
    value: '4250',
    name: '4250',
  },
  {
    value: '4500',
    name: '4500',
  },
  {
    value: '4750',
    name: '4750',
  },
  {
    value: '5000',
    name: '5000',
  },
  // {
  //   value: '5250',
  //   name: '5250',
  // },
  // {
  //   value: '5500',
  //   name: '5500',
  // },
  // {
  //   value: '5750',
  //   name: '5750',
  // },
  // {
  //   value: '6000',
  //   name: '6000',
  // },
  // {
  //   value: '6250',
  //   name: '6250',
  // },
  // {
  //   value: '6500',
  //   name: '6500',
  // },
  // {
  //   value: '6750',
  //   name: '6750',
  // },
  // {
  //   value: '7000',
  //   name: '7000',
  // },
  // {
  //   value: '7250',
  //   name: '7250',
  // },
  // {
  //   value: '7500',
  //   name: '7500',
  // },
  // {
  //   value: '7750',
  //   name: '7750',
  // },
  // {
  //   value: '8000',
  //   name: '8000',
  // },
  // {
  //   value: '8250',
  //   name: '8250',
  // },
  // {
  //   value: '8500',
  //   name: '8500',
  // },
  // {
  //   value: '8750',
  //   name: '8750',
  // },
  // {
  //   value: '9000',
  //   name: '9000',
  // },
  // {
  //   value: '9250',
  //   name: '9250',
  // },
  // {
  //   value: '9500',
  //   name: '9500',
  // },
  // {
  //   value: '9750',
  //   name: '9750',
  // },
  // {
  //   value: '10000',
  //   name: '10000',
  // },
];


export const FA_OV_MENGE_RANGES: IMengeRange[] = [
  {
    value: '250',
    name: '250',
  },
  {
    value: '500',
    name: '500',
  },
  {
    value: '750',
    name: '750',
  },
  ...MENGE_RANGES
];
