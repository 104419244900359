import React, {ChangeEvent, useState} from 'react';
import {Box, styled} from "@mui/material";
import CheckboxElement from "../Checkbox/CheckboxElement";

const Wrapper = styled(Box)`
  width: 140px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

interface IProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const MatrixCell = ({ checked, onChange }: IProps) => {
  const [hover, setHover] = useState<boolean>();

  return (
    <Wrapper
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <CheckboxElement
        myhover={String(hover)}
        checked={checked}
        onChange={onChange}
        sx={{
          padding: '8px 62px'
        }}
      />
    </Wrapper>
  );
};

export default MatrixCell;
