import {ConfigStateType, IDoor, IGroup} from "./types";
import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep, remove} from "lodash";
import { K10_TUREN_VARIANTS, K6_TUREN_VARIANTS, T250_TUREN_VARIANTS } from "../constants/turen";

const initialState: ConfigStateType = {
  projectName: '',
  productId: null,
  productType: null,
  totalDoors: 1,
  doors: [],
  totalGroups: 1,
  groups: [],
  draftDoor: null,
  draftGroup: null,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setProjectName: (state, { payload }: { payload: string }) => {
      state.projectName = payload;
    },
    setConfigProduct: (state, { payload }) => {
      state.productId = payload;
    },
    setProductType: (state, { payload }) => {
      state.productType = payload;
    },
    setConfigTotalDoors: (state, { payload }) => {
      state.totalDoors = payload;
    },
    setConfigDoors: (state, { payload }: { payload: IDoor[] }) => {
      state.doors = payload;
    },
    updateConfigDoor: (state, { payload }: { payload: IDoor }) => {
      const index = state.doors.findIndex((k) => k.id === payload.id);
      state.doors[index] = payload;
    },
    pushDoor: (state, { payload }: { payload: IDoor }) => {
      state.doors.push(payload);
      state.totalDoors = Number(state.totalDoors) + 1;
    },
    removeDoor: (state, { payload }) => {
      remove(state.doors, (door) => {
        return door.id === payload;
      });

      // remove all invalid door ids in all groups
      const allGroups = cloneDeep(state.groups);
      state.groups = allGroups.map((group) => ({
        ...group,
        doorIds: group.doorIds.filter((doorId: string) => doorId !== payload),
      }));
      state.totalDoors = Number(state.totalDoors) - 1;

      if (state.draftGroup) {
        const draft = cloneDeep(state.draftGroup);
        state.draftGroup = {
          ...draft,
          doorIds: draft.doorIds.filter((doorId: string) => doorId !== payload),
        }
      }
    },
    addDoor: (state, { payload }: { payload: { visited: boolean, id: string, language: string } }) => {
      const hebelvariationenDefaultValue = (state.productId === '1'
        ? T250_TUREN_VARIANTS
        : state.productId === '2'
        ? K10_TUREN_VARIANTS
        : K6_TUREN_VARIANTS)[0].value;

      const newDoor = {
        id: payload.id,
        name: '',
        type: 'doppelzylinder1',
        langeAusen: '31',
        langeInnen: '31',
        bugelhohe: '30',
        hebelvariationen: hebelvariationenDefaultValue,
        visited: payload.visited,
      }
      state.doors.push(newDoor);
      state.totalDoors = Number(state.totalDoors) + 1;
    },
    copyDoor: (state, { payload: { newDoorId, doorIndex }}) => {
      const newDoorsArray = state.doors.slice(0, doorIndex + 1);
      newDoorsArray.push(
        {
          ...state.doors[doorIndex],
          id: newDoorId,
          name: `${state.doors[doorIndex].name}`,
        },
        ...state.doors.slice(doorIndex + 1),
      );
      state.doors = newDoorsArray;
    },
    setDoorName: (state, { payload: { doorIndex, name } }) => {
      if (state?.doors[doorIndex]?.name === undefined) {
        return;
      }
      state.doors[doorIndex].name = name;
    },
    setDoorType: (state, { payload: { doorIndex, type } }) => {
      if (!state?.doors[doorIndex]?.type) {
        return;
      }
      state.doors[doorIndex].type = type;
    },
    setDoorLangeAusen: (state, { payload: { doorIndex, lange } }) => {
      if (!state?.doors[doorIndex]?.langeAusen) {
        return;
      }
      state.doors[doorIndex].langeAusen = lange;
    },
    setDoorLangeInnen: (state, { payload: { doorIndex, lange } }) => {
      if (!state?.doors[doorIndex]?.langeInnen) {
        return;
      }
      state.doors[doorIndex].langeInnen = lange;
    },
    setDoorBugelhohe: (state, { payload: { doorIndex, lange } }) => {
      if (!state?.doors[doorIndex]?.bugelhohe) {
        return;
      }
      state.doors[doorIndex].bugelhohe = lange;
    },
    setDoorHebelvariationen: (state, { payload: { doorIndex, lange } }) => {
      if (!state?.doors[doorIndex]?.hebelvariationen) {
        return;
      }
      state.doors[doorIndex].hebelvariationen = lange;
    },
    setDoorVisited: (state, { payload: { doorIndex, visited } }) => {
      if (state.doors[doorIndex]) {
        state.doors[doorIndex].visited = visited;
      }
    },
    setConfigTotalGroups: (state, { payload }) => {
      state.totalGroups = payload;
    },
    setConfigGroups: (state, { payload }: { payload: IGroup[] }) => {
      state.groups = payload;
    },
    updateConfigGroup: (state, { payload }: { payload: IGroup }) => {
      const index = state.groups.findIndex((k) => k.id === payload.id);
      state.groups[index] = payload;
    },
    pushGroup: (state, { payload }: { payload: IGroup }) => {
      state.groups.push(payload);
      state.totalGroups = Number(state.totalGroups) + 1;
    },
    removeGroup: (state, { payload }) => {
      remove(state.groups, (group) => {
        return group.id === payload;
      });
      state.totalGroups = Number(state.totalGroups) - 1;
    },
    addGroup: (state, { payload }: { payload: { visited: boolean, id: string } }) => {
      const newGroup = {
        id: payload.id,
        name: '',
        keysCount: 1,
        doorIds: [],
        visited: payload.visited,
      }
      state.groups.push(newGroup);
      state.totalGroups = Number(state.totalGroups) + 1;
    },
    copyGroup: (state, { payload: { newGroupId, groupIndex }}) => {
      const newGroupArray = state.groups.slice(0, groupIndex + 1);
      newGroupArray.push(
        {
          ...state.groups[groupIndex],
          id: newGroupId,
          name: `${state.groups[groupIndex].name}`,
        },
        ...state.groups.slice(groupIndex + 1),
      );
      state.groups = newGroupArray;
    },
    setGroupName: (state, { payload: { groupIndex, name } }) => {
      state.groups[groupIndex].name = name;
    },
    setGroupKeysCount: (state, { payload: { groupIndex, keys } }) => {
      state.groups[groupIndex].keysCount = keys;
    },
    setGroupVisited: (state, { payload: { groupIndex, visited } }) => {
      if (state.groups[groupIndex]) {
        state.groups[groupIndex].visited = visited;
      }
    },
    setGroupDoorIds: (state, { payload: { groupIndex, doorIds } }) => {
      state.groups[groupIndex].doorIds = doorIds;
    },
    setGroupDoorId: (state, { payload: { groupIndex, doorId, isAdd } }) => {
      if (isAdd) {
        state.groups[groupIndex].doorIds.push(doorId);
      } else {
        state.groups[groupIndex].doorIds = state.groups[groupIndex].doorIds
          .filter((id) => id !== doorId);
      }
    },
    resetConfig: () => {
      return initialState;
    },
    setDraftDoor(state, { payload }: { payload: IDoor | null }) {
      state.draftDoor = payload;
    },
    setDraftGroup: (state, { payload }: { payload: IGroup | null }) => {
      state.draftGroup = payload;
    },
  },
});

export const {
  setProjectName,
  setConfigProduct,
  setProductType,
  setConfigTotalDoors,
  setConfigDoors,
  updateConfigDoor,
  removeDoor,
  addDoor,
  copyDoor,
  setDoorName,
  setDoorType,
  setDoorLangeAusen,
  setDoorLangeInnen,
  setDoorBugelhohe,
  setDoorHebelvariationen,
  setDoorVisited,
  setConfigTotalGroups,
  setConfigGroups,
  updateConfigGroup,
  removeGroup,
  addGroup,
  copyGroup,
  setGroupName,
  setGroupKeysCount,
  setGroupVisited,
  setGroupDoorIds,
  setGroupDoorId,
  resetConfig,
  setDraftDoor,
  setDraftGroup,
  pushDoor,
  pushGroup,
} = configSlice.actions;

export default configSlice.reducer;
