import React, { useState } from 'react';
import {Box, styled} from "@mui/material";
import circleBlue from '../../../assets/img/circleWithCheckBlue.svg';
import circle from '../../../assets/img/circle.svg';
import menu from '../../../assets/img/menu.svg';
import Dropdown from '../Dropdown/Dropdown';
import editIcon from '../../../assets/img/edit-blue.svg';
import deleteIcon from '../../../assets/img/trash-blue.svg';
interface WrapperProps {
  mywidth: number;
}

const Wrapper = styled(Box)<WrapperProps>`
  position: relative;
  width: ${props => props.mywidth + 'px'};
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color .2s ease-in;
  
  .edit {
    margin-left: 4px;
    margin-left: auto;
    opacity: 0;
    transition: opacity .2s ease-in;
  }

  &:hover .edit {
    opacity: 1;
  }

  &:hover .dropdown {
    opacity: 1;
  }

  &:hover {
    background-color: #fff;
  }
`;

const HeadingText = styled(Box)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06326E;
  text-overflow: ellipsis;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
`;

interface IProps {
  width?: number;
  text: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  isVisited?: boolean;
}

const dropdownList = [
  {
    value: 'edit',
    icon: editIcon,
    text: 'Bearbeiten',
  },
  {
    value: 'delete',
    icon: deleteIcon,
    text: 'Löschen',
  }
];

const MatrixHeading = ({
  text,
  width,
  onEditClick = () => undefined,
  onDeleteClick = () => undefined,
  isVisited,
}: IProps) => {
  const [hover, setHover] = useState<boolean>(false);

  const handleClick = (value: string) => {
    if (value === 'edit') {
      onEditClick();
    }

    if (value === 'delete') {
      onDeleteClick();
    }
  };

  return (
    <Wrapper
      mywidth={width || 160}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={isVisited ? circleBlue : circle}
        alt=''
      />
      <HeadingText className=''>{ text }</HeadingText>
      <img
        className='edit'
        src={menu}
        alt='(edit)'
      />

      <Dropdown
        translation={true}
        list={dropdownList}
        show={hover}
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default MatrixHeading;
