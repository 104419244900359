import { ReactNode } from "react";
import { Box, styled } from "@mui/material";

const noContainer = window.location.search.includes("container=false");

const Wrapper = styled(Box)`
  width: ${noContainer ? "100vw" : "1150px"};
  min-height: 630px;
  margin: ${noContainer ? "0" : "160px 16px 16px"};
  background: #f5f5f5;
  border-radius: 16px;
  position: relative;
  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 100vw;
    overflow-y: scroll;
    margin: 0;
    border-radius: 0;
    height: 100dvh;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
  }
`;

interface IProps {
  children: ReactNode;
  maxHeight?: string;
  minHeight?: string;
}

const WorkArea = ({
  children,
  maxHeight = "unset",
  minHeight = "630px",
}: IProps) => {
  return (
    <Wrapper
      sx={{
        maxHeight,
        minHeight,
      }}
    >
      {children}
    </Wrapper>
  );
};

export default WorkArea;
