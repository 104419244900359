// import {useState} from 'react';
import {Box, styled} from "@mui/material";
import TextRegularGrey from "../../UI/Typography/TextRegularGrey";
import TextStepName from '../../UI/Typography/TextStepName';
import Button from "../../UI/Button/Button";
// import download from '../../../assets/img/download.svg';
import svgNext from "../../../assets/img/arrowRight.svg";
import useAppDispatch from "../../../hooks/useDispatch";
import {resetConfig} from "../../../store/configSlice";
import {resetApp} from "../../../store/appSlice";
import {HOME_SITE} from "../../../constants/common";
// import usePrepareConfig from "../../../hooks/usePrepareConfig";
// import {httpClient} from "../../../client/httpClient";
// import downloadFile from '../../../tools/downloadFile';
import useAppSelector from '../../../hooks/useSelector';
import { useTranslation } from "react-i18next";
import { resetContactForm } from "../../../store/contactSlice";
import { resetKeyApp } from "../../../store/appKeysSlice";

const Wrapper = styled(Box)`
  width: 1088px;
  height: 372px;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  margin-top: 160px;
  position: relative;
`;

const TextBox = styled(Box)`
  max-width: 730px;
  margin: 0 auto;
  width: 100%;
  height: 264px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
`;

const ButtonBox = styled(Box)`
  position: absolute;
  bottom: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Thanks = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.contact.email);

  const handleGoHome = () => {
    window.location.href = HOME_SITE;
  }

  const handleCreateNew = () => {
    dispatch(resetConfig());
    dispatch(resetApp());
    dispatch(resetContactForm());
    dispatch(resetKeyApp());
  }

  const buttonText = process.env.REACT_APP_DEV_ENV_KEYS === '1' ? 'thanks.button-keys' : 'thanks.button';

  return (
    <Wrapper>
      <TextBox>
        <TextStepName>
          {t('thanks.title')}
        </TextStepName>
        <TextStepName>
          {t('thanks.subtitle')}
        </TextStepName>
        <TextRegularGrey>
          {t('thanks.text1')} <span style={{ color: '#06326E' }}>{email}</span> {t('thanks.text2')}
        </TextRegularGrey>

        <TextRegularGrey
          onClick={handleGoHome}
          sx={{
            cursor: 'pointer',
          }}
        >
          <span>Homepage BASI</span>
        </TextRegularGrey>
      </TextBox>

      <ButtonBox>
        <Button
          onClick={handleCreateNew}
          // disabled={loading}
        >
          {t(buttonText)}
          <img
            src={svgNext}
            alt=''
          />
        </Button>
      </ButtonBox>
    </Wrapper>
  );
};

export default Thanks;
