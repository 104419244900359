import { styled } from "@mui/system";

export const OverallTable = styled('table')`
  border-collapse: separate;
  border-spacing: 8px;
  width: max-content;
`;

export const OverallHeader = styled('thead')`
  margin-bottom: 8px;
  position: relative;
  z-index: 30;
`;

export const OverallBody = styled('tbody')`
  position: relative;
  z-index: 10;
`;

export const OverallHeaderCell = styled('th')`
  width: 120px;
  top: 0;
  z-index: 1;
  background: #F5F5F5;
  border: 0;
  padding: 4px;
`;

export const OverallOverlapCell = styled('td')`
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
`;