import { IKeysBlankNames } from "../constants/pragerohlinge";
import { IKey, TSelectedKeyTypes } from "../store/keysTypes";

const generateKeyArray = (array: IKey[]) => {
  const result: TSelectedKeyTypes = {
    [IKeysBlankNames.FA]: [],
    [IKeysBlankNames.OV]: [],
    [IKeysBlankNames.ORIGINAL]: [],
  };

  array.forEach((key) => {
    if (key.blank) {
      result[key.blank as IKeysBlankNames].push(key);
    }
  });

  return result;
};

export default generateKeyArray;