import {ISwitchButtonsOptions} from "../components/UI/SwitchButtons/SwitchButtons";

export const OrderOptions: ISwitchButtonsOptions[] = [
  {
    name: 'Neukunde',
    value: 'Inquiry',
  },
  {
    name: 'Kunde',
    value: 'Order',
  },
]
