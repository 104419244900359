import { useTranslation } from "react-i18next";
import {
  IKeyProfiles,
  IKeysBlankNames,
  KEYS_PRODUKT_CARDS,
} from "../../../constants/pragerohlinge";
import useAppDispatch from "../../../hooks/useDispatch";
import useAppSelector from "../../../hooks/useSelector";
import {
  removeSelectedKeyType,
  setSelectedKeyTypes,
} from "../../../store/appKeysSlice";
import { generateKey, generateKeyByBlank } from "../../../tools/generateKey";
import TextStepName from "../../UI/Typography/TextStepName";
import KeysProductCard from "./components/KeysProductCard";
import { KeysCardsContainer } from "./styles";
import { useMemo } from "react";
import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    flex-grow: 1;
  }
`;

const Pragerohlinge = () => {
  const selectedKeyTypes = useAppSelector(
    (state) => state.appKeys.selectedKeyTypes
  );
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const selectedKeysArray = useMemo(() => {
    return Object.values(selectedKeyTypes).flat();
  }, [selectedKeyTypes]);

  const getKeyTypesArray = useMemo(() => {
    return (blank: string) => {
      return selectedKeysArray.filter((keyType) => keyType.blank === blank);
    };
  }, [selectedKeysArray]);

  const handleChangeSelect = async (
    value: IKeyProfiles | undefined,
    blank: IKeysBlankNames
  ) => {
    if (
      !selectedKeysArray.find((item) => item.value === value?.value) &&
      value
    ) {
      let key;
      if (
        (blank === IKeysBlankNames.FA || blank === IKeysBlankNames.OV) &&
        selectedKeyTypes[blank].length
      ) {
        key = generateKeyByBlank(value, blank, selectedKeyTypes[blank][0]);
      } else {
        key = await generateKey(value, blank);
      }
      dispatch(setSelectedKeyTypes(key));
      return;
    }
    dispatch(removeSelectedKeyType({ value: value?.value, blank }));
  };

  const handleRemoveSelectItem = (
    value: IKeyProfiles | undefined,
    blank: IKeysBlankNames
  ) => {
    dispatch(removeSelectedKeyType({ value: value?.value, blank }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingBottom: "64px",
      }}
    >
      <TextStepName sx={{ marginBottom: "28px" }}>
        {t("keys.title")}
      </TextStepName>
      <Wrapper>
        <KeysCardsContainer>
          {KEYS_PRODUKT_CARDS.map((item) => (
            <KeysProductCard
              key={item.id}
              data={item}
              isSelected={selectedKeysArray.some(
                (key) => key.blank === item.title
              )}
              keysTypes={item.keysTypes}
              selectedKeyTypes={getKeyTypesArray(item.title)}
              onChangeSelect={(value: IKeyProfiles | undefined) =>
                handleChangeSelect(value, item.title)
              }
              onRemoveItem={(value: IKeyProfiles | undefined) =>
                handleRemoveSelectItem(value, item.title)
              }
            />
          ))}
        </KeysCardsContainer>
      </Wrapper>
    </div>
  );
};

export default Pragerohlinge;
