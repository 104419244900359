import { Box, Icon, styled } from "@mui/material";
import { ILanguageItem } from "../../../constants/language";
import { ImageLanguageBox, TextLanguageBox, WrapperLanguageInner } from "./styles";
import chevron from '../../../assets/img/selectChevron.svg';
import { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
  position: relative;
  margin-left: auto;
`;

interface IProps {
  icon?: string;
  value: string;
  list: ILanguageItem[];
  onChangeLanguage: (value: string) => void;
}

const LanguageDropdown = ({ icon, value, onChangeLanguage, list }: IProps) => {
  const { i18n } = useTranslation();
  const [hover, setHover] = useState<boolean>(false); 

  const handleClick = (value: string) => {
    onChangeLanguage(value);
    i18n.changeLanguage(value.toLowerCase());
  };

  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <WrapperLanguageInner>
        {
          icon &&  
          <ImageLanguageBox>
            <img src={icon} alt="(i)"/>
          </ImageLanguageBox>
        }

        <TextLanguageBox>
          {value}
        </TextLanguageBox>

        <Icon style={{ pointerEvents: 'none' }}>
          <img
            src={chevron}
            alt=''
          />
        </Icon>
      </WrapperLanguageInner>

      <Dropdown
        list={list}
        show={hover}
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default LanguageDropdown;