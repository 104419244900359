import { Box, SxProps, styled } from "@mui/material";
import { ReactNode } from "react";

const Wrapper = styled(Box)`
  grid-template-rows: 1fr 72px;
  min-height: 527px;
  padding: 16px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
    min-height: unset;
    height: 100%;
  }
`;

interface IProps {
  children: ReactNode;
  sx?: SxProps;
}

const LocksContent = ({ children, sx }: IProps) => {
  return <Wrapper sx={sx}>{children}</Wrapper>;
};

export default LocksContent;
