import { useTranslation } from "react-i18next";
import TextLabel from "../Typography/TextLabel";
import { Box, styled } from "@mui/system";

interface IDropdownWrapper {
  show: string;
}

export const DropdownWrapper = styled(Box)<IDropdownWrapper>`
  width: 100%;
  min-width: 120px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #00000014;
  opacity: ${props => props.show === 'true' ? 1 : 0};
  transition: opacity .2s ease-in;
  pointer-events: ${props => props.show === 'true' ? 'auto' : 'none'};
  z-index: 50;
`;

export const DropdownItem = styled(Box)`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  transition: background-color .2s ease-in;
  cursor: pointer;

  &:hover {
    background-color: rgba(25, 118, 210, 0.05);
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const DropdownImageBox = styled(Box)`
  margin-right: 16px;
  line-height: 0;
  width: 18px;
  height: 18px;

  img {
    width: 100%;
    height: 100%;
  }
`

type DropdownListItem = {
  text?: string;
  value: string;
  icon?: string;
};

interface IProps {
  list: DropdownListItem[];
  show: boolean;
  translation?: boolean;
  onClick: (value: string) => void;
}

const Dropdown = ({ list, show, translation = false, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <DropdownWrapper
      className="dropdown"
      show={String(show)}
    >
      {
        list.map(item => (
          <DropdownItem
            key={item.value}
            onClick={() => onClick(item.value)}
          >
            {
              item.icon &&
              <DropdownImageBox>
                <img src={item.icon} alt="(i)" />
              </DropdownImageBox>
            }
            {
              item.text &&
              <TextLabel
                sx={{ lineHeight: '0', color: '#828282' }}
              >
                {
                  translation
                    ? t(`dropdown.${item.value}`)
                    : item.text
                }
              </TextLabel>
            }
          </DropdownItem>
        ))
      }
    </DropdownWrapper>
  )
};

export default Dropdown;