import useAppDispatch from "../../../../hooks/useDispatch";
import useAppSelector from "../../../../hooks/useSelector";
import {
  setDeletionId,
  setKeyMenge,
  setKeysModalName,
  setMengeModalType,
  setSelectedKeyId,
} from "../../../../store/appKeysSlice";
import { IKeysModalsCommon } from "../../../../store/keysTypes";
import Button from "../../../UI/Button";
import ZusammenfassungItem from "./ZusammenfassungItem";
import plusSvg from "../../../../assets/img/plus.svg";
import { useEffect } from "react";
import { Box, styled } from "@mui/material";
import { SCROLL_CSS } from "../../../../constants/common";
// import { t } from "i18next";
// import TextCardTitle from "../../../UI/Typography/TextCardTitle";
import { ZusammenfassungSelectorParameters } from "../styles";
import { IKeysBlankNames } from "../../../../constants/pragerohlinge";
import { useTranslation } from "react-i18next";
import useKeysPaginator from "../../../../hooks/useKeysPaginator";

const Wrapper = styled(Box)`
  position: relative;
  padding-top 5px;
  width: 100%;

  .draggable-item {
    display: flex;
  }

  .draggable-item__drag {
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
  }
`;

const WrapperInner = styled(Box)`
  min-height: 360px;
  max-height: 440px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;

  ${SCROLL_CSS};

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-height: unset;
    min-height: unset;
    width: unset;
    padding-right: 0;
    display: block;
  }
`;

const Title = styled("span")`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

const ZusammenfassungParameters = () => {
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { onChangeTab } = useKeysPaginator();

  useEffect(() => {
    if (!Object.values(keys).flat().length) {
      onChangeTab(1);
    }
  }, [keys]);

  const handleChangeSelect = (
    value: string,
    index: number,
    blank: IKeysBlankNames
  ) => {
    dispatch(setKeyMenge({ value, keyIndex: index, blank }));
  };

  const handleClickTooltip = (value: IKeysModalsCommon, blank: string) => {
    dispatch(setMengeModalType(blank));
    dispatch(setKeysModalName(value));
  };

  const handleClickEdit = (id: string) => {
    dispatch(setSelectedKeyId(id));
    onChangeTab(2);
  };

  const handleClickDelete = (id: string) => {
    dispatch(setDeletionId(id));
    dispatch(setKeysModalName(IKeysModalsCommon.MODAL_DELETE_KEY));
  };

  const handleClickMoreButton = () => {
    onChangeTab(1);
  };

  return (
    <>
      <WrapperInner>
        <Title>{t("key-summary.amount-text")}</Title>
        <Box>
          {Object.entries(keys)
            .filter(([blank, value]) => value.length)
            .map(([blank, value]) => (
              <ZusammenfassungSelectorParameters key={blank}>
                <Box>
                  {value.map((item, index) => (
                    <ZusammenfassungItem
                      key={item.id}
                      name={item.name}
                      menge={item.menge}
                      blank={item.blank}
                      profile={item.value}
                      value={item.value}
                      frontImage={item.front.image}
                      backImage={item.back.image}
                      onChangeMenge={(value) =>
                        handleChangeSelect(
                          value as string,
                          index,
                          blank as IKeysBlankNames
                        )
                      }
                      onClickTooltip={handleClickTooltip}
                      onClickDelete={() => handleClickDelete(item.id)}
                      onClickEdit={() => handleClickEdit(item.id)}
                    />
                  ))}
                </Box>
              </ZusammenfassungSelectorParameters>
            ))}
        </Box>
        <Button
          variant="second"
          sx={{
            padding: "8px",
            minWidth: "40px",
            marginRight: "8px",
            background: "transparent",
          }}
          onClick={handleClickMoreButton}
        >
          <img src={plusSvg} alt="(i)" />
        </Button>
      </WrapperInner>
      <Box
        sx={{
          position: "absolute",
          bottom: "100%",
          right: "0",
          fontWeight: 500,
          fontSize: "14px",
        }}
      >
        {t("key-summary.amount-text")}
      </Box>
    </>
  );
};

export default ZusammenfassungParameters;
