import {styled, Typography} from "@mui/material";

const TextTableHeader = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #06326E;
`;

export default TextTableHeader;
