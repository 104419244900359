import {styled, Typography} from "@mui/material";

const TextRegularGrey = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  
  span {
    color: #06326E;
  }
`;

export default TextRegularGrey;
