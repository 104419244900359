import {useState} from 'react';
import {Box, css, IconButton, styled} from "@mui/material";
import trash from '../../../assets/img/trash.svg';
import copy from '../../../assets/img/copy.svg';
import {CardCircleIconRed, CardLeftColumn, CardTitle, Buttons, CardIcon, CardCircleIconBlack, CardCircleIconBlue} from "./styles";
import Circle from "../../../assets/img/svgComponents/Circle";
import CircleWithCheck from "../../../assets/img/svgComponents/CircleWithCheck";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
  hover: string;
  isselected: string;
  isentered: string;
  clickable: string;
}

const Wrapper = styled(Box)<WrapperProps>`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
  height: 40px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color .2s ease-in, border-color .2s ease-in;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  ${props => props.clickable === 'true' && props.hover === 'true' && css`
    background-color: #FFFFFF;
  `}

  ${props => props.clickable === 'true' && props.isselected === 'true' && css`
    background-color: #FFFFFF;
    border-color: #E20031;
  `}

  ${props => props.isentered === 'true' && props.isselected === 'true' && css`
    border-color: #000;
  `}

  & ~ .draggable-item__drag {
    display: ${props => props.clickable === 'true' && props.isselected === 'true' ? 'flex' : 'none'};
  }
`;

interface IProps {
  id?: string;
  title: string;
  isSelected?: boolean;
  isVisited?: boolean;
  isEntered?: boolean;
  image?: string;
  clickable?: boolean;
  onClick: () => void;
  onClickRemove: () => void;
  onClickCopy?: () => void;
}

const Card = ({
  id,
  title,
  isSelected,
  isVisited,
  isEntered = false,
  image,
  clickable = true,
  onClick,
  onClickRemove,
  onClickCopy,
}: IProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClick = () => {
    if (clickable) {
      onClick();
    }
  }

  return (
    <Wrapper
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      hover={String(hover)}
      isselected={String(isSelected)}
      isentered={String(isEntered)}
      clickable={String(clickable)}
      onClick={handleClick}
      id={id}
    >
      <CardLeftColumn>
        {
          clickable &&
          <Box>
            {
              isVisited && isEntered ?
                <CardCircleIconBlack
                  hover={String(hover || isSelected)}
                >
                  <CircleWithCheck />
                </CardCircleIconBlack> :
                isVisited ?
                  <CardCircleIconBlue
                    hover={String(hover || isSelected)}
                  >
                    <CircleWithCheck />
                  </CardCircleIconBlue>

                  : <CardCircleIconRed
                    hover={String(hover || isSelected)}
                  >
                    <Circle />
                  </CardCircleIconRed>
            }
          </Box>
        }
        <CardTitle
          sx={{
            color: isEntered && (clickable && (hover || isSelected)) ? '#000' : '#06326E',
            maxWidth: '130px',
          }}
        >
          { title }
        </CardTitle>
      </CardLeftColumn>
      {
        image &&
        <CardIcon>
          <img 
            src={image}
            alt='(i)'
          />
        </CardIcon>
      }
      {
        ((clickable && hover) || isSelected) &&
          <Buttons>
            <Tooltip
              title={t('controls.delete')}
              arrow
            >
              <IconButton
                sx={{
                  padding: '5px',
                }}
                onClick={onClickRemove}
              >
                <img
                  src={trash}
                  alt='delete'
                />
              </IconButton>
            </Tooltip>

            {
              onClickCopy &&
              <Tooltip
                title={t('controls.copy')}
                arrow
              >
                <IconButton
                  sx={{
                    padding: '5px',
                  }}
                  onClick={onClickCopy}
                >
                  <img
                    src={copy}
                    alt='copy'
                  />
                </IconButton>
              </Tooltip>
            }
          </Buttons>
      }
    </Wrapper>
  );
};

export default Card;
