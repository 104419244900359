import {Box, styled, Typography} from "@mui/material";

export const StyledHeader = styled('thead')`
  position: relative;
  z-index: 30;
`;

export const StyledBody = styled('tbody')`
  position: relative;
  z-index: 10;
`;

export const StyledHeaderCell = styled('th')`
  top: 0;
  z-index: 1;
  background: #F5F5F5;
`;

export const StyledDoorColumnCell = styled('td')`
  width: 140px;
  left: 0;
  background: #F5F5F5;
`;

export const StyledTable = styled('table')`
  padding-left: 196px;
  border-collapse: collapse;
  border-spacing: 0;
  
  td, th {
    border-width: 0;
    padding: 4px;
    margin: 0;
  }
`;

export const HideCorner = styled('div')`
  width: 140px;
  height: 48px;
  left: 0;
  top: 0;
  background: #F5F5F5;
  z-index: 50;
`;

export const InfoHeadingText = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #666666;
  margin-bottom: 24px;
`;

export const InfoBox = styled(Box)`
  margin-bottom: 16px;
`;

export const InfoOptionText = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #666666;
`;

export const InfoValueText = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
`;
