import {useCallback} from 'react';
import Dialog from "../../UI/Dialog/Dialog";
import useAppSelector from "../../../hooks/useSelector";
import useAppDispatch from "../../../hooks/useDispatch";
import { setKeysModalName } from '../../../store/appKeysSlice';
import ModalLogo from '../../KeysModals/ModalLogo';
import { IKeysModalsCommon } from '../../../store/keysTypes';
import ModalMenge from '../../KeysModals/ModalMenge';
import ModalDeleteKey from '../../KeysModals/ModalDeleteKey';

const KeysModalController = () => {
  const modalName = useAppSelector((state) => state.appKeys.modalName);

  const dispatch = useAppDispatch();

  const handleCloseDialog = useCallback(() => {
    dispatch(setKeysModalName(null));
  }, []);

  return (
    <>
      <Dialog
        open={modalName === IKeysModalsCommon.MODAL_LOGO}
        onClose={handleCloseDialog}
      >
        <ModalLogo
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === IKeysModalsCommon.MODAL_MENGE}
        onClose={handleCloseDialog}
      >
        <ModalMenge
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === IKeysModalsCommon.MODAL_DELETE_KEY}
        onClose={handleCloseDialog}
      >
        <ModalDeleteKey
          onClose={handleCloseDialog}
        />
      </Dialog>
    </>
  );
};

export default KeysModalController;
