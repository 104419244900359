import { Box, styled } from "@mui/material";
import TextStepName from "../../UI/Typography/TextStepName";
import PragungParameters from "./components/PragungParameters";
import PragungSelector from "./components/PragungSelector";
import { PragungLayout } from "./styles";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)`
  padding-bottom: 55px;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex: 1;
    height: 100%;
    overflow-y: scroll;
  }
`;

const Pragung = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TextStepName sx={{ marginBottom: "28px" }}>
        {t("embossing.title")}
      </TextStepName>
      <PragungLayout>
        <PragungSelector />
        <PragungParameters />
      </PragungLayout>
    </Wrapper>
  );
};

export default Pragung;
