import { IKeysBlankNames } from "./pragerohlinge";

type TTextElementPositions = {
  [blank: string]: {
    default: {
      [name: string]: number;
    };
    zoom: {
      [name: string]: number;
    };
    zoomBack?: {
      [name: string]: number;
    };
    defaultBack?: {
      [name: string]: number;
    };
  };
};

export const TEXT_ELEMENT_POSITION: TTextElementPositions = {
  [IKeysBlankNames.FA]: {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  [IKeysBlankNames.OV]: {
    default: {
      A: 0,
      B: 0,
      C: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
    },
  },
  "basi-bmfa": {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "basi-vbfa": {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "abu-91fa": {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "basi-vfa": {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "ci-48fa": {
    default: {
      A: -12,
      B: -12,
      C: -12,
      D: -12,
    },
    defaultBack: {
      A: -30,
      B: -30,
      C: -30,
      D: -30,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "is-15fa": {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  flafa: {
    default: {
      A: -14,
      B: -14,
      C: -14,
      D: -14,
    },
    zoom: {
      A: -28,
      B: -28,
      C: -28,
      D: -28,
    },
  },
  "agb-1fa": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
  },
  "ff-1dfa": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
  },
  "u-4dfa": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
  },
  "abu-17d": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
      G: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
      G: 0,
    },
    defaultBack: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
      G: 0,
    },
    zoomBack: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
      G: 0,
    },
  },
  "abu-86d": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
  },
  "bk-7d": {
    default: {
      A: 4,
      B: 4,
      C: 4,
      D: 4,
      E: 4,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
    },
  },
  "ce-53d": {
    default: {
      A: 0,
      B: 0,
    },
    zoom: {
      A: 0,
      B: 0,
    },
  },
  "wil-66d": {
    default: {
      A: 0,
      B: 0,
      C: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
    },
  },
  "win-22": {
    default: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
    zoom: {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    },
  },
};
