import { Box, InputLabel, styled } from "@mui/material";
import downloadIcon from "../../../assets/img/download.svg";
import deleteIcon from "../../../assets/img/trash-blue.svg";
import { FileLoaderControls, FileLoaderErrorText, FileLoaderImage, FileLoaderReadyWrapper, FileLoaderText } from "./style";
import { ChangeEvent, useRef, useState } from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const Wrapper = styled(InputLabel)`
  width: 195px;
  min-height: 40px;
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: .2s ease background-color;

  &:hover,
  &:focus {
    background-color: #E0E0E0;
  }
`;

const UploadButtonWrapper = styled(InputLabel)`
  padding: 8px;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
`

type IProps = {
  text?: string;
  file?: string;
  onChangeFile: (value: File | null) => void;
}

const FileLoader = ({ text = 'Bild hochladen', file, onChangeFile }: IProps) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (e?.target?.files?.length) {
      if (!['image/jpg', 'image/png', 'image/jpeg', 'image/svg+xml'].includes(e.target.files[0].type)) {
        setFileError('format');
        return;
      }

      if (e.target.files[0].size > 5000000) {
        setFileError('size');
        return;
      }

      onChangeFile(e.target.files[0]);
      fileReader.onload = (event) => {
        if (event.target?.result) {
          setFileError('');
          return;
        }
      };
    }
  };

  const handleDeleteFile = () => {
    onChangeFile(null);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    
    if (e?.dataTransfer?.files[0]) {
      if (!['image/jpg', 'image/png', 'image/jpeg'].includes(e.dataTransfer.files[0].type)) {
        setFileError('format');
        return;
      }

      if (e.dataTransfer.files[0].size > 5000000) {
        setFileError('size');
        return;
      }
      onChangeFile(e?.dataTransfer?.files[0]);
    }
  };

  const handleDragEvent = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      {
        !file &&
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          onDrop={handleDropFile}
          onDragOver={handleDragEvent}
          onDragEnter={handleDragEvent}
        >
          <Wrapper>
            <input
              accept="image/*"
              ref={inputRef}
              style={{ display: 'none' }}
              hidden
              type="file"
              onChange={handleChangeFile}
            />
            <FileLoaderText
              sx={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'unset',
                marginRight: '16px',
              }}
            >
              {text}
            </FileLoaderText>

            <Box sx={{ transform: 'rotate(180deg)', display: 'flex', alignItems: 'center' }}>
              <img src={downloadIcon} alt='(i)' />
            </Box>
          </Wrapper>
          {
            fileError &&
            <FileLoaderErrorText>
              {t(`file-loader.error.${fileError}`)}
            </FileLoaderErrorText>
          }
        </Box>
      }
      {
        file &&
        <FileLoaderReadyWrapper
          onDrop={handleDropFile}
          onDragOver={handleDragEvent}
          onDragEnter={handleDragEvent}
        >
          <FileLoaderImage>
            <img src={file} alt='(i)' />
          </FileLoaderImage>

          <FileLoaderControls>
            <UploadButtonWrapper
            >
              <input
                accept="image/*"
                ref={inputRef}
                style={{ display: 'none' }}
                hidden
                type="file"
                onChange={handleChangeFile}
              />
              <Box sx={{ transform: 'rotate(180deg)', display: 'flex', alignItems: 'center' }}>
                <img src={downloadIcon} alt='(i)' />
              </Box>
            </UploadButtonWrapper>

            <Button
              variant='second'
              sx={{ padding: '8px', minWidth: '40px' }}
              onClick={handleDeleteFile}
            >
              <img src={deleteIcon} alt='(i)' />
            </Button>
          </FileLoaderControls>
        </FileLoaderReadyWrapper>
      }
    </>
  )
};

export default FileLoader;