import { Box, styled } from "@mui/material";

export const ZusammenfassungLayout = styled(Box)`
  width: 100%;
  min-height: 360px;
  display: grid;
  grid-template-columns: 200px 548px 1fr;
  grid-column-gap: 12px;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: block;
  }
`;

export const ZusammenfassungListItem = styled(Box)`
  background-color: #fff;
  border-radius: 16px;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const ZusammenfassungItemHeader = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const ZusammenfassungItemBody = styled(Box)`
  display: flex;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ZusammenfassungItemImage = styled(Box)`
  width: 252px;
  height: 307px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ZusammenfassungSelectorProfiles = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const ZusammenfassungSelectorParameters = styled(Box)`
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
