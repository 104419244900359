import { styled, Box } from "@mui/material";

export const CardsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap: 32px;
  }
`;
