import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import configSlice from "./configSlice";
import contactSlice from "./contactSlice";
import appKeysSlice from "./appKeysSlice";
import languageSlice from "./languageSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    config: configSlice,
    contact: contactSlice,
    appKeys: appKeysSlice,
    language: languageSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
