import { Box, styled } from "@mui/material";
import { SCROLL_CSS } from "../../../../constants/common";
import useAppSelector from "../../../../hooks/useSelector";
import TextLabelBlue from "../../../UI/Typography/TextLabelBlue";
import { useTranslation } from "react-i18next";
import TextCardTitle from "../../../UI/Typography/TextCardTitle";
import { ZusammenfassungSelectorProfiles } from "../styles";

const Wrapper = styled(Box)`
  padding-top 5px;
  min-height: 360px;
  max-height: 54vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    min-height: unset;
  }

  .draggable-item {
    display: flex;
  }

  .draggable-item__drag {
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
  }

  ${SCROLL_CSS};
`;

const ZusammenfassungList = () => {
  const { t } = useTranslation();
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);

  return (
    <Wrapper>
      <TextLabelBlue sx={{ marginBottom: "24px" }}>
        {t("key-summary.profile-title")}
      </TextLabelBlue>
      {Object.entries(keys)
        .filter(([blank, value]) => value.length)
        .map(([blank, value]) => (
          <ZusammenfassungSelectorProfiles key={blank}>
            <TextCardTitle sx={{ marginBottom: "16px", fontWeight: "400" }}>
              {t(`keys.${blank}`)}
            </TextCardTitle>
            <Box>
              {value.map((item) => (
                <TextLabelBlue
                  key={item.id}
                  sx={{
                    paddingLeft: "8px",
                    marginBottom: "24px",
                    fontWeight: "400",
                  }}
                >
                  {item.name}
                </TextLabelBlue>
              ))}
            </Box>
          </ZusammenfassungSelectorProfiles>
        ))}
    </Wrapper>
  );
};

export default ZusammenfassungList;
