import { IKeysBlankNames } from "./pragerohlinge";

type IImageCanvasWidth = {
  [blank: string]: number;
}

export const IMAGE_CANVAS_WIDTHS: IImageCanvasWidth = {
  [IKeysBlankNames.FA]: 251.5,
  [IKeysBlankNames.OV]: 224,
  'basi-bmfa': 236,
  'basi-vbfa': 194,
  'abu-91fa': 192,
  'basi-vfa': 204,
  'ci-48fa': 204,
  'is-15fa': 211,
  'flafa': 260,
  'agb-1fa': 195,
  'ff-1dfa': 195,
  'u-4dfa': 195,
  'abu-17d': 189,
  'abu-86d': 228,
  'bk-7d': 257,
  'ce-53d': 222,
  'wil-66d': 228,
  'win-22': 195,
};

type IImageCanvasPosition = {
  [blank: string]: {
    default: number;
    zoom: number;
  }
}

export const IMAGE_CANVAS_POSITION: IImageCanvasPosition = {
  [IKeysBlankNames.FA]: {
    default: 72,
    zoom: -48.5,
  },
  [IKeysBlankNames.OV]: {
    default: 82,
    zoom: -30,
  },
  'basi-bmfa': {
    default: 83,
    zoom: -25,
  },
  'basi-vbfa': {
    default: 93,
    zoom: -5,
  },
  'abu-91fa': {
    default: 100,
    zoom: 5,
  },
  'basi-vfa': {
    default: 97,
    zoom: 0,
  },
  'ci-48fa': {
    default: 102,
    zoom: 12,
  },
  'is-15fa': {
    default: 72,
    zoom: -68.5,
  },
  'flafa': {
    default: 80,
    zoom: -36,
  },
  'agb-1fa': {
    default: 96,
    zoom: -1,
  },
  'ff-1dfa': {
    default: 96,
    zoom: -1,
  },
  'u-4dfa': {
    default: 96,
    zoom: -1,
  },
  'abu-17d': {
    default: 97,
    zoom: 7,
  },
  'abu-86d': {
    default: 85,
    zoom: -35,
  },
  'bk-7d': {
    default: 60,
    zoom: -66,
  },
  'ce-53d': {
    default: 80,
    zoom: -30,
  },
  'wil-66d': {
    default: 77,
    zoom: -43,
  },
  'win-22': {
    default: 96,
    zoom: -1,
  },
};

export const IMAGE_BACK_CANVAS_POSITION: IImageCanvasPosition = {
  [IKeysBlankNames.FA]: {
    default: 66,
    zoom: -62.5,
  },
  [IKeysBlankNames.OV]: {
    default: 81,
    zoom: -39,
  },
  'basi-bmfa': {
    default: 72,
    zoom: -48.5,
  },
  'basi-vbfa': {
    default: 99,
    zoom: 5,
  },
  'abu-91fa': {
    default: 98,
    zoom: 3,
  },
  'basi-vfa': {
    default: 88,
    zoom: -18,
  },
  'ci-48fa': {
    default: 82,
    zoom: -29,
  },
  'is-15fa': {
    default: 82,
    zoom: -48.5,
  },
  'flafa': {
    default: 51,
    zoom: -92,
  },
  'agb-1fa': {
    default: 93,
    zoom: -5,
  },
  'ff-1dfa': {
    default: 93,
    zoom: -5,
  },
  'u-4dfa': {
    default: 93,
    zoom: -5,
  },
  'abu-17d': {
    default: 97,
    zoom: 7,
  },
  'abu-86d': {
    default: 86,
    zoom: -34,
  },
  'bk-7d': {
    default: 62,
    zoom: -63.5,
  },
  'ce-53d': {
    default: 80,
    zoom: -32.5,
  },
  'wil-66d': {
    default: 82.5,
    zoom: -34,
  },
  'win-22': {
    default: 93,
    zoom: -5,
  },
};