import {ChangeEvent} from 'react';
import {Box, styled} from "@mui/material";
import useAppSelector from "../../../../hooks/useSelector";
import {BenutzerParametersLayout} from "../styles";
import TextField from "../../../UI/TextField/TextField";
import FormInput from "../../../UI/FormInput";
import useAppDispatch from "../../../../hooks/useDispatch";
import {
  setGroupKeysCount,
  setGroupName,
} from "../../../../store/configSlice";
import validateNumberInput from "../../../../tools/validateNumberInput";
import {MAX_KEYS} from "../../../../constants/common";
import {setModalName} from "../../../../store/appSlice";
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
  width: 100%;
  height: 360px;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
`;

const GroupParameters = () => {
  const { t } = useTranslation();
  const groups = useAppSelector((state) => state.config.groups);
  const selectedGroupId = useAppSelector((state) => state.app.selectedGroupId);
  const group = groups.find((k) => k.id === selectedGroupId);
  const groupIndex = groups.findIndex((k) => k.id === selectedGroupId);

  const dispatch = useAppDispatch();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setGroupName({
      groupIndex,
      name: e.target.value,
    }));
  }

  const handleChangeKeysCount = (e: ChangeEvent<HTMLInputElement>) => {
    const value = validateNumberInput(e.target.value, 1, MAX_KEYS);
    dispatch(setGroupKeysCount({
      groupIndex,
      keys: value,
    }));
  }

  const onClickTooltip = (modalName: string) => {
    dispatch(setModalName(modalName));
  }

  return (
    <Wrapper>
      {
        group ?
          <BenutzerParametersLayout>
            <div>
              <FormInput
                label={t('benutzer.group-name')}
                sx={{
                  marginBottom: '24px',
                }}
                tooltipAction={() => onClickTooltip('groupName')}
              >
                <TextField
                  value={group.name}
                  error={!group.name}
                  onChange={handleChangeName}
                  maxLength={16}
                />
              </FormInput>

              <FormInput
                label={t('benutzer.keys-amount')}
                sx={{
                  marginBottom: '24px',
                }}
              >
                <TextField
                  value={group.keysCount}
                  onChange={handleChangeKeysCount}
                  type='number'
                />
              </FormInput>
            </div>
          </BenutzerParametersLayout>
          : null
      }
    </Wrapper>
  );
};

export default GroupParameters;
