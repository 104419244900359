import {createSlice} from "@reduxjs/toolkit";
import { AppKeysStateType, IKey, IKeySideFont, IKeySideLogoSize, IKeySideName, IKeyType, IKeysModalsCommon, TSelectedKeyTypes } from "./keysTypes";
import { IKeysBlankNames } from "../constants/pragerohlinge";

const initialState: AppKeysStateType = {
  page: 1,
  enabledTabIds: [1],
  selectedKeyTypes: {
    [IKeysBlankNames.FA]: [],
    [IKeysBlankNames.OV]: [],
    [IKeysBlankNames.ORIGINAL]: [],
  },
  selectedKeyId: null,
  modalName: null,
  mengeModalType: null,
  deletionId: null,
  comment: '',
}

export const appKeysSlice = createSlice({
  name: 'keys',
  initialState,
  reducers: {
    setKeysModalName: (state, { payload }: { payload: IKeysModalsCommon|null }) => {
      state.modalName = payload;
    },
    setKeysPage: (state, { payload }) => {
      state.page = payload;
    },
    enableKeysTabId: (state, { payload }) => {
      if (!state.enabledTabIds.includes(payload)) {
        state.enabledTabIds.push(payload);
      }
    },
    disableKeysTabId: (state, { payload }) => {
      state.enabledTabIds = state.enabledTabIds.filter(item => item !== payload);
    },
    setSelectedKeyTypes: (state, { payload }: { payload: IKey }) => {
      const { blank } = payload;
      if (blank) {
        state.selectedKeyTypes[blank].push(payload);
      }
    },
    removeSelectedKeyType: (state, { payload }: { payload: { value: string|undefined, blank: IKeysBlankNames } }) => {
      const { value, blank } = payload;
      if (blank && value) {
        state.selectedKeyTypes[blank] = state.selectedKeyTypes[blank].filter((item) => item.value !== value);
      }
    },
    setKeyTypes: (state, { payload }: { payload: TSelectedKeyTypes }) => {
      state.selectedKeyTypes = payload;
    },
    setSelectedKeyId: (state, { payload }) => {
      state.selectedKeyId = payload;
    },
    setVisited: (state, { payload: { keyIndex, visited, blank } }: { payload: { keyIndex: number, visited: boolean, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex].visited = visited;
      }
    },
    setKeyMenge: (state, { payload: { keyIndex, value, blank } }: { payload: { keyIndex: number, value: string, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex].menge = value;
      }
    },
    setKeySideType: (state, { payload: { keyIndex, side, type, blank } }: { payload: { keyIndex: number, side: IKeySideName , type: IKeyType, blank: IKeysBlankNames|undefined }}) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].type = type;
      }
    },
    setKeyTextField: (state, { payload: { keyIndex, side, textIndex, value, blank } }: { payload: { keyIndex: number, side: IKeySideName, textIndex: number, value: string, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].text[textIndex].value = value;
      }
    },
    setKeyFont: (state, { payload: { keyIndex, side, value, blank } }: { payload: { keyIndex: number, side: IKeySideName, value: IKeySideFont, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].font = value;
      }
    },
    setKeyFontSize: (state, { payload: { keyIndex, name, side, value, blank } }: { payload: { keyIndex: number, name: string, side: IKeySideName, value: number, blank: IKeysBlankNames|undefined} }) => {
      if (blank && state.selectedKeyTypes?.[blank]?.[keyIndex]?.[side]?.size?.[name]) {
        console.log(name, value)
        state.selectedKeyTypes[blank][keyIndex][side].size[name] = value;
      }
    },
    setKeyLogo: (state, { payload: { keyIndex, side, value, blank } }: { payload: { keyIndex: number, side: IKeySideName, value: string, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].logo = value;
      }
    },
    setKeyLogoSize: (state, { payload: { keyIndex, side, value, blank } }: { payload: { keyIndex: number, side: IKeySideName, value: IKeySideLogoSize, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].logoSize = value;
      }
    },
    setDeletionId: (state, { payload }) => {
      state.deletionId = payload;
    },
    setKeyImage: (state, { payload: { keyIndex, side, value, blank }}: { payload: { keyIndex: number, side: IKeySideName, value: string, blank: IKeysBlankNames|undefined } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].image = value;
      }
    },
    setScreenshotReady: (state, { payload: { keyIndex, blank, side, value } }: { payload: { keyIndex: number, blank: IKeysBlankNames, side: IKeySideName, value: boolean } }) => {
      if (blank && state.selectedKeyTypes[blank][keyIndex]) {
        state.selectedKeyTypes[blank][keyIndex][side].ready = value;
      }
    },
    setMengeModalType: (state, { payload }) => {
      state.mengeModalType = payload;
    },
    resetKeyApp: () => {
      return initialState;
    },
  },
});

export const {
  setKeysPage,
  enableKeysTabId,
  disableKeysTabId,
  setScreenshotReady,
  setSelectedKeyTypes,
  removeSelectedKeyType,
  setSelectedKeyId,
  setVisited,
  setKeyTypes,
  setKeyMenge,
  setKeySideType,
  setKeyTextField,
  setKeyFont,
  setKeyFontSize,
  setKeyLogo,
  setKeysModalName,
  setKeyLogoSize,
  setDeletionId,
  setKeyImage,
  setMengeModalType,
  resetKeyApp,
} = appKeysSlice.actions;

export default appKeysSlice.reducer;