import {Box, styled} from "@mui/material";
import {SCROLL_CSS} from "../../../constants/common";

export const BenutzerCounterBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BenutzerContentLayout = styled(Box)`
  width: 100%;
  min-height: 360px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 12px;
`;

export const BenutzerParametersLayout = styled(Box)`
  display: grid;
  grid-template-columns: 388px 1fr;
  grid-column-gap: 32px;
`;

export const BenutzerParametersScroll = styled(Box)`
  height: 236px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  
  ${SCROLL_CSS};
`;
