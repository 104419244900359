import { ReactNode } from 'react';
import {Button, styled, SxProps} from "@mui/material";
import {CardPlus, ButtonPart} from "./styles";
import Plus from "../../../assets/img/svgComponents/Plus";

const Wrapper = styled(Button)`
  box-sizing: border-box;
  width: auto;
  min-width: 40px;
  height: 40px;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  padding: 0 8px;

  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06326E;
  text-transform: none;
`;

interface IProps {
  onAdd: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  labelText?: string;
  sx?: SxProps;
  disabled?: boolean;
  children?: ReactNode;
}

const AddCardButton = ({ onAdd, labelText, sx, disabled, children, onMouseEnter, onMouseLeave }: IProps) => {
  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={sx}
      disabled={disabled}
    >
      <ButtonPart
        onClick={onAdd}
      >
        <CardPlus
          disabled={disabled}
        >
          <Plus/>
        </CardPlus>
        {
          labelText ?
            <>{ labelText }</>
            : null
        }
      </ButtonPart>
      {children}
    </Wrapper>
  );
};

export default AddCardButton;
