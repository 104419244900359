import { styled, Box } from "@mui/material";
import TextTableHeader from "../../../UI/Typography/TextTableHeader";
import TextTableHeaderOptionTitle from "../../../UI/Typography/TextTableOptionTitle";
import TextTableHeaderOptionText from "../../../UI/Typography/TextTableOptionText";
import { EZylinderTypesValues, TUREN_ZYLINDER_TYPES } from "../../../../constants/turen";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Wrapper = styled('td')`
  width: 140px;
  height: 120px;
  left: 0;
  background: #F5F5F5;
  border: 0;
  border-top: 1px solid #E0E0E0;
  padding: 8px;
`;

interface IProps {
  name: string;
  type: string|undefined;
  langeAusen?: string;
  langeInnen?: string;
  bugelhohe?: string;
}

const OverallColumnCell = ({
  name,
  type,
  langeAusen,
  langeInnen,
  bugelhohe,
}: IProps) => {
  const { t } = useTranslation();

  const showLange = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find(item => item.value === type)?.showLange;
  }, [type]);

  return (
    <Wrapper>
      <TextTableHeader
        sx={{ textAlign: 'left', fontSize: '14px' }}
      >
        {name}
      </TextTableHeader>

      <Box>
        <TextTableHeaderOptionTitle
          sx={{ marginRight: '8px' }}
        >
          {t('summary.table.cylinder-type')}
        </TextTableHeaderOptionTitle>

        <TextTableHeaderOptionText
          sx={{ fontWeight: '500' }}
        >
          {t(`select.${type}`)}
        </TextTableHeaderOptionText>
      </Box>

      {
        showLange &&
        <Box
          sx={{ display: 'flex' }}
        >
          <TextTableHeaderOptionTitle
            sx={{ marginRight: '8px' }}
          >
            {t('summary.table.outside-length')}
          </TextTableHeaderOptionTitle>

          <TextTableHeaderOptionText
            sx={{ fontWeight: '500' }}
          >
            {langeAusen} mm
          </TextTableHeaderOptionText>
        </Box>
      }

      {
        showLange &&
        <Box
          sx={{ display: 'flex' }}
        >
          <TextTableHeaderOptionTitle
            sx={{ marginRight: '8px' }}
          >
            {t('summary.table.inside-length')}
          </TextTableHeaderOptionTitle>

          <TextTableHeaderOptionText
            sx={{ fontWeight: '500' }}
          >
            {langeInnen} mm
          </TextTableHeaderOptionText>
        </Box>
      }

      {
        type === EZylinderTypesValues.VORHANGSCHLOSS &&
        <Box
          sx={{ display: 'flex' }}
        >
          <TextTableHeaderOptionTitle
            sx={{ marginRight: '8px' }}
          >
            {t('summary.table.ironing-height')}
          </TextTableHeaderOptionTitle>

          <TextTableHeaderOptionText
            sx={{ fontWeight: '500' }}
          >
            {bugelhohe} mm
          </TextTableHeaderOptionText>
        </Box>
      }
    </Wrapper>
  );
};

export default OverallColumnCell;