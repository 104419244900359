import { Select, Icon, styled, SelectChangeEvent } from "@mui/material";
import chevron from "../../../assets/img/selectChevron.svg";
import { StyledMenuItem } from "./styles";
import { useTranslation } from "react-i18next";

export interface ISelectOptions {
  value: string;
  name: string;
}

interface StyledSelectProps {
  background: string;
  bordercolor: string;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-right: 8px;

  .MuiSelect-select {
    background: transparent;
    padding: 0 22px 0 8px;
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
  }

  .MuiIcon-root {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  fieldset {
    border: none;
  }
`;

interface IProps {
  options: ISelectOptions[];
  value: string | string[] | undefined;
  placeholder?: string;
  multipleValues?: ISelectOptions[];
  multiple?: boolean;
  disabled?: boolean;
  background?: string;
  bordercolor?: string;
  translate?: boolean;
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  multipleValues = [],
  multiple = false,
  disabled = false,
  translate = false,
  background = "white",
  bordercolor = "#E0E0E0",
}: IProps) => {
  const { t } = useTranslation();

  const getStyles = (value: string) => {
    if (multipleValues?.length) {
      return {
        color: multipleValues.find((item) => item.value === value)
          ? "#E20031"
          : "#666666",
      };
    }
    return {};
  };

  return (
    <>
      <StyledSelect
        variant="outlined"
        IconComponent={() => (
          <Icon style={{ pointerEvents: "none" }}>
            <img src={chevron} alt="" />
          </Icon>
        )}
        background={background}
        bordercolor={bordercolor}
        disabled={disabled}
        value={value}
        multiple={multiple}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            sx: {
              background: "#FFFFFF",
              border: "1px solid #E0E0E0",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
              borderRadius: "8px",
              marginTop: "8px",
              maxHeight: "400px",
            },
          },
        }}
      >
        {placeholder && (
          <StyledMenuItem
            value={placeholder}
            disabled
            style={{ color: "#666666" }}
          >
            {placeholder}
          </StyledMenuItem>
        )}
        {options.map((item) => (
          <StyledMenuItem
            key={item.value}
            value={item.value}
            style={getStyles(item.value)}
          >
            {translate ? t(`select.${item.value}`) : item.name}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </>
  );
};

export default CustomSelect;
