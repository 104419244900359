import React, {ReactNode} from 'react';
import {Button, styled} from "@mui/material";

interface WithValueProps {
  isselected: string;
}

const Wrapper = styled(Button)<WithValueProps>`
  height: 36px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: ${props => props.isselected === 'true' ? '#E20031' : '#06326E'};
  position: relative;
  z-index: 1;
  
  &:hover {
    background: none;
  }
`;

interface IProps {
  children: ReactNode;
  isSelected: boolean;
  onChange: () => void;
  width: number;
}

const ButtonWithValue = ({ children, isSelected, onChange, width }: IProps) => {
  const handleChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChange();
  };

  return (
    <Wrapper
      isselected={String(isSelected)}
      onClick={handleChange}
      disableRipple
      sx={{ width: width + 'px' }}
    >
      { children }
    </Wrapper>
  );
};

export default ButtonWithValue;
