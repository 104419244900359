import axios from 'axios';
import { onRequestError, onRequestPrepare, onResponseError, onResponsePrepare } from './interceptors';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(onResponsePrepare, onResponseError);
instance.interceptors.request.use(onRequestPrepare, onRequestError);
