import { useMemo } from "react";
import useAppSelector from "./useSelector";
import { disableKeysTabId, enableKeysTabId, setKeysPage, setScreenshotReady, setSelectedKeyId, setVisited } from "../store/appKeysSlice";
import useAppDispatch from "./useDispatch";
import { IKeysBlankNames } from "../constants/pragerohlinge";
import { IKeySideName } from "../store/keysTypes";

const useKeysPaginator = () => {
  const page = useAppSelector((state) => state.appKeys.page);
  const selectedKeyTypes = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const enabledTabIds = useAppSelector((state) => state.appKeys.enabledTabIds);

  const selectedKeysArray = useMemo(() => {
    return Object.values(selectedKeyTypes).flat();
  }, [selectedKeyTypes]);

  const dispatch = useAppDispatch();

  const nextPage = (newPage: number) => {
    dispatch(setKeysPage(newPage));
    dispatch(enableKeysTabId(newPage));
  }

  const onChangeTab = (newVal: number) => {
    if (page > newVal) {
      validatePreviousPage(newVal);
      return;
    }
    nextPage(newVal);
  };

  const validateNextPage = (newPage: number) => {
    switch (page) {
      case 1: {
        if (selectedKeysArray.length) {
          nextPage(newPage);
        }
        break;
      }
      case 2: {
        const unvisitedKey = selectedKeysArray.find((item) => !item.visited);
        const blank = unvisitedKey?.blank;
        if (unvisitedKey && blank) {
          dispatch(setSelectedKeyId(unvisitedKey?.id));
          if (
            blank === IKeysBlankNames.FA
            || blank === IKeysBlankNames.OV
          ) {
            selectedKeyTypes[blank].forEach((item, index) => {
              dispatch(setVisited({
                keyIndex: index,
                visited: true,
                blank: blank,
              }));
            });
            break;
          }
          dispatch(setVisited({
            keyIndex: selectedKeyTypes[blank].findIndex((item) => !item.visited),
            visited: true,
            blank: unvisitedKey.blank,
          }));
          break
        }

        if (!unvisitedKey) {
          nextPage(newPage);
        }
        break
      }
      case 3: {
        if (selectedKeysArray.length) {
          nextPage(newPage);
        }
        break
      }
    }
  };

  const validatePreviousPage = (newPage: number) => {
    enabledTabIds.forEach((item) => {
      if (item > newPage) {
        dispatch(disableKeysTabId(item));
      }
    });
    if (newPage === 1 || newPage === 2) {
      selectedKeysArray.forEach((item) => {
        const itemIndex = selectedKeyTypes[item.blank].findIndex(key => item.id === key.id);
        setScreenshotReady({ keyIndex: itemIndex, blank: item.blank, side: IKeySideName.FRONT, value: false });
        setScreenshotReady({ keyIndex: itemIndex, blank: item.blank, side: IKeySideName.BACK, value: false });
      });
    }
    nextPage(newPage);
  };

  const onBack = () => {
    validatePreviousPage(page - 1);
  };

  const onForward = () => {
    validateNextPage(page + 1);
  };

  return {
    onChangeTab,
    onBack,
    onForward,
    disablePagination: !selectedKeysArray.length,
    disableForward: !selectedKeysArray.length,
  };
};

export default useKeysPaginator;