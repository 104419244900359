import {styled, Typography} from "@mui/material";

const TextModalHeading = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #06326E;
  word-break: break-word;
`;

export default TextModalHeading;
