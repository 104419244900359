import {ContactStateType, OrderType } from "./types";
import {createSlice} from "@reduxjs/toolkit";

const initialState: ContactStateType = {
  name: '',
  company: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  zip: '',
  customerNumber: '',
  comment: '',
  orderType: 'Inquiry',
  thanksWindow: false,
  loading: false,
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContactName: (state, { payload }) => {
      state.name = payload;
    },
    setContactEmail: (state, { payload }) => {
      state.email = payload;
    },
    setContactPhone: (state, { payload }) => {
      state.phone = payload;
    },
    setCompany: (state, { payload }) => {
      state.company = payload;
    },
    setStreet: (state, { payload }) => {
      state.street = payload;
    },
    setCity: (state, { payload }) => {
      state.city = payload;
    },
    setZip: (state, { payload }) => {
      state.zip = payload;
    },
    setComment: (state, { payload }) => {
      state.comment = payload;
    },
    setCustomerNumber: (state, { payload }) => {
      state.customerNumber = payload;
    },
    setOrderType: (state, { payload }: { payload: OrderType }) => {
      state.orderType = payload;
    },
    setThanksWindow: (state, { payload }) => {
      state.thanksWindow = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    resetContactForm: () => {
      return initialState;
    },
  }
});

export const {
  setContactName,
  setContactEmail,
  setContactPhone,
  resetContactForm,
  setStreet,
  setCity,
  setCompany,
  setZip,
  setComment,
  setCustomerNumber,
  setOrderType,
  setThanksWindow,
  setLoading,
} = contactSlice.actions;

export default contactSlice.reducer;
