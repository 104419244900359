import { Box, styled } from "@mui/material";
// import useAppDispatch from "./hooks/useDispatch";
import LocksPage from "./pages/LocksPage/LocksPage";
import KeysPage from "./pages/KeysPage/KeysPage";

const noContainer = window.location.search.includes("container=false");

const Wrapper = styled(Box)`
  width: ${noContainer ? "98vw" : "100vw"};
  height: 100dvh;
  margin: ${noContainer ? "0 auto" : ""};
`;

function App() {
  return (
    <Wrapper>
      {process.env.REACT_APP_DEV_ENV_KEYS === "1" ? (
        <KeysPage title={"BASI Prägerohling Konfigurator"} />
      ) : (
        <LocksPage title={"BASI Schließanlagen - Konfigurator"} />
      )}
      <span style={{ visibility: "hidden" }}>1.1.0</span>
    </Wrapper>
  );
}

export default App;
