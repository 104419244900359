import {styled, Typography} from "@mui/material";

const TextTableHeaderOptionTitle = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
`;

export default TextTableHeaderOptionTitle;
