import React from 'react';
import {Box, styled} from "@mui/material";
import ButtonWithValue from "./components/ButtonWithValue";
import ButtonAdornment from "./components/ButtonAdornment";
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  padding: 2px;
  gap: 2px;
  height: 40px;
  background: #eaeaea;
  border-radius: 20px;
  position: relative;
`;

export interface ISwitchButtonsOptions {
  name: string;
  value: string;
}

interface IProps {
  options: ISwitchButtonsOptions[];
  currentValue: string;
  translation?: boolean;
  onChange: (value: string) => void;
  buttonWidth?: number;
}

const SwitchButtons = ({ options, currentValue, translation = false, onChange, buttonWidth }: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {
        options.map((item) => <ButtonWithValue
          key={item.value}
          isSelected={item.value === currentValue}
          onChange={() => onChange(item.value)}
          width={buttonWidth ?? 144}
        >
          {
            translation
              ? t(`switch-buttons.${item.value}`)
              : item.name
          }
        </ButtonWithValue>)
      }
      <ButtonAdornment
        index={options.findIndex((k) => k.value === currentValue)}
        width={buttonWidth ?? 144}
      />
    </Wrapper>
  );
};

export default SwitchButtons;
