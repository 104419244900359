import React, {ChangeEvent} from 'react';
import {Box, styled} from "@mui/material";
import {MAX_DOORS, MAX_GROUPS, SCROLL_CSS} from "../../../../constants/common";
import MatrixHeading from "../../../UI/Matrix/MatrixHeading";
import useAppSelector from "../../../../hooks/useSelector";
import useAppDispatch from "../../../../hooks/useDispatch";
import {setDraftDoor, setDraftGroup, setGroupDoorId} from "../../../../store/configSlice";
import {setModalName, setSelectedDoorId, setSelectedGroupId} from "../../../../store/appSlice";
import generateGroups from "../../../../tools/generateGroups";
import AddCardButton from "../../../UI/Card/AddCardButton";
import generateDoors from "../../../../tools/generateDoors";
import MatrixCell from "../../../UI/Matrix/MatrixCell";
import {HideCorner, StyledBody, StyledDoorColumnCell, StyledHeader, StyledHeaderCell, StyledTable } from '../styles';

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  gap: 32px;
`;

const ScrollContainer = styled(Box)`
  width: 1040px;
  height: 360px;
  overflow: auto;
  flex-shrink: 0;

  ${SCROLL_CSS};
`;

const TableLayout = () => {
  const doors = useAppSelector((state) => state.config.doors);
  const groups = useAppSelector((state) => state.config.groups);
  const draft = useAppSelector((state) => state.config.draftGroup);
  const language = useAppSelector((state) => state.language.value);
  const productId = useAppSelector((state) => state.config.productId);

  const dispatch = useAppDispatch();

  const handleCheckDoor = (groupId: string, doorId: string, isAdd: boolean) => {
    const groupIndex = groups.findIndex((k) => k.id === groupId);
    dispatch(setGroupDoorId({
      groupIndex,
      doorId,
      isAdd,
    }));
  }

  const handleEditGroup = (id: string) => {
    dispatch(setSelectedGroupId(id));
    dispatch(setModalName('editGroup'));
  }

  const handleAddGroup = () => {
    if (!draft) {
      const newDraftArray = generateGroups(1, groups);
      const newDraft = newDraftArray[newDraftArray.length - 1];
      newDraft.visited = true;
      dispatch(setDraftGroup(newDraft));
    }

    dispatch(setSelectedGroupId(null));
    dispatch(setModalName('editGroup'));
  }

  const handleEditDoor = (id: string) => {
    dispatch(setSelectedDoorId(id));
    dispatch(setModalName('editDoor'));
  }

  const handleAddDoor = () => {
    if (!draft) {
      const newDraftArray = generateDoors(1, doors, language, productId);
      const newDraft = newDraftArray[newDraftArray.length - 1];
      newDraft.visited = true;
      dispatch(setDraftDoor(newDraft));
    }

    dispatch(setSelectedDoorId(null));
    dispatch(setModalName('editDoor'));
  }

  const handleDeleteGroup = (id: string) => {
    dispatch(setSelectedGroupId(id));
    dispatch(setModalName('removeGroup'));
  } 

  const handleDeleteDoor = (id: string) => {
    dispatch(setSelectedDoorId(id));
    dispatch(setModalName('removeDoor'));
  }

  return (
    <Wrapper>
      <ScrollContainer>
        <StyledTable>
          <StyledHeader>
            <tr>
              <StyledHeaderCell />
              {
                groups.map((k) => <StyledHeaderCell key={k.id}>
                  <MatrixHeading
                    text={k.name}
                    onEditClick={() => handleEditGroup(k.id)}
                    onDeleteClick={() => handleDeleteGroup(k.id)}
                    isVisited={k.visited}
                  />
                </StyledHeaderCell>)
              }
              <StyledHeaderCell>
                <AddCardButton
                  onAdd={handleAddGroup}
                  disabled={groups.length >= MAX_GROUPS}
                  sx={{ width: '40px' }}
                />
              </StyledHeaderCell>
            </tr>
          </StyledHeader>
          <StyledBody>
            {
              doors.map((door) => {
                return <tr key={door.id}>
                  <StyledDoorColumnCell>
                    <MatrixHeading
                      text={door.name}
                      width={188}
                      onEditClick={() => handleEditDoor(door.id)}
                      onDeleteClick={() => handleDeleteDoor(door.id)}
                      isVisited={door.visited}
                    />
                  </StyledDoorColumnCell>
                  {
                    groups.map((group) => <td
                      key={group.id}
                    >
                      <MatrixCell
                        checked={group.doorIds.includes(door.id)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckDoor(group.id, door.id, e.target.checked)}
                      />
                    </td>)
                  }
                </tr>
              })
            }
            <tr>
              <StyledDoorColumnCell>
                <AddCardButton
                  onAdd={handleAddDoor}
                  disabled={doors.length >= MAX_DOORS}
                  sx={{ width: '40px' }}
                />
              </StyledDoorColumnCell>
            </tr>
          </StyledBody>
        </StyledTable>
      </ScrollContainer>
      <HideCorner />
    </Wrapper>
  );
};

export default TableLayout;
