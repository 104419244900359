import { Box, styled } from "@mui/material";

export const TurenCounterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 325px;
  gap: 32px;
`;

export const TurenContentLayout = styled(Box)`
  width: 100%;
  min-height: 360px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 12px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
  }
`;

export const ProjectNameBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    width: 50%;
  }
`;

export const TurenParametersLayout = styled(Box)`
  display: grid;
  grid-template-columns: 388px 1fr;
  grid-column-gap: 32px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

export const BlueprintBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    height: 263px;
    cursor: pointer;
  }
`;
