import { IKeysBlankNames } from "./pragerohlinge";

type TTextContainerMask = {
  [blank: string]: {
    default: {
      [name: string]: {
        x: number;
        rectWidth: number;
      };
    };
    zoom: {
      [name: string]: {
        x: number;
        rectWidth: number;
      };
    };
    defaultBack?: {
      [name: string]: {
        x: number;
        rectWidth: number;
      };
    };
    zoomBack?: {
      [name: string]: {
        x: number;
        rectWidth: number;
      };
    };
  };
};

export const TEXT_CONTAINER_MASK: TTextContainerMask = {
  [IKeysBlankNames.FA]: {
    default: {
      A: {
        x: 120,
        rectWidth: 148,
      },
      B: {
        x: 120,
        rectWidth: 148,
      },
      C: {
        x: 120,
        rectWidth: 148,
      },
      D: {
        x: 120,
        rectWidth: 148,
      },
    },
    zoom: {
      A: {
        x: 50,
        rectWidth: 288,
      },
      B: {
        x: 50,
        rectWidth: 288,
      },
      C: {
        x: 50,
        rectWidth: 288,
      },
      D: {
        x: 50,
        rectWidth: 288,
      },
    },
  },
  [IKeysBlankNames.OV]: {
    default: {
      A: {
        x: 126,
        rectWidth: 140,
      },
      B: {
        x: 135,
        rectWidth: 120,
      },
      C: {
        x: 145,
        rectWidth: 100,
      },
    },
    zoom: {
      A: {
        x: 50,
        rectWidth: 290,
      },
      B: {
        x: 60,
        rectWidth: 270,
      },
      C: {
        x: 80,
        rectWidth: 240,
      },
    },
  },
  "basi-bmfa": {
    default: {
      A: {
        x: 140,
        rectWidth: 110,
      },
      B: {
        x: 140,
        rectWidth: 110,
      },
      C: {
        x: 140,
        rectWidth: 110,
      },
      D: {
        x: 140,
        rectWidth: 110,
      },
    },
    zoom: {
      A: {
        x: 80,
        rectWidth: 235,
      },
      B: {
        x: 80,
        rectWidth: 235,
      },
      C: {
        x: 80,
        rectWidth: 235,
      },
      D: {
        x: 80,
        rectWidth: 235,
      },
    },
  },
  "basi-vbfa": {
    default: {
      A: {
        x: 120,
        rectWidth: 148,
      },
      B: {
        x: 120,
        rectWidth: 148,
      },
      C: {
        x: 120,
        rectWidth: 148,
      },
      D: {
        x: 120,
        rectWidth: 148,
      },
    },
    zoom: {
      A: {
        x: 50,
        rectWidth: 288,
      },
      B: {
        x: 50,
        rectWidth: 288,
      },
      C: {
        x: 50,
        rectWidth: 288,
      },
      D: {
        x: 50,
        rectWidth: 288,
      },
    },
  },
  "abu-91fa": {
    default: {
      A: {
        x: 140,
        rectWidth: 200,
      },
      B: {
        x: 140,
        rectWidth: 110,
      },
      C: {
        x: 140,
        rectWidth: 110,
      },
      D: {
        x: 140,
        rectWidth: 110,
      },
    },
    zoom: {
      A: {
        x: 80,
        rectWidth: 230,
      },
      B: {
        x: 80,
        rectWidth: 230,
      },
      C: {
        x: 80,
        rectWidth: 230,
      },
      D: {
        x: 80,
        rectWidth: 230,
      },
    },
  },
  "basi-vfa": {
    default: {
      A: {
        x: 120,
        rectWidth: 148,
      },
      B: {
        x: 120,
        rectWidth: 148,
      },
      C: {
        x: 120,
        rectWidth: 148,
      },
      D: {
        x: 120,
        rectWidth: 148,
      },
    },
    zoom: {
      A: {
        x: 50,
        rectWidth: 288,
      },
      B: {
        x: 50,
        rectWidth: 288,
      },
      C: {
        x: 50,
        rectWidth: 288,
      },
      D: {
        x: 50,
        rectWidth: 288,
      },
    },
  },
  "ci-48fa": {
    default: {
      A: {
        x: 140,
        rectWidth: 110,
      },
      B: {
        x: 140,
        rectWidth: 110,
      },
      C: {
        x: 140,
        rectWidth: 110,
      },
      D: {
        x: 140,
        rectWidth: 110,
      },
    },
    zoom: {
      A: {
        x: 90,
        rectWidth: 210,
      },
      B: {
        x: 90,
        rectWidth: 210,
      },
      C: {
        x: 90,
        rectWidth: 210,
      },
      D: {
        x: 90,
        rectWidth: 210,
      },
    },
  },
  "is-15fa": {
    default: {
      A: {
        x: 120,
        rectWidth: 148,
      },
      B: {
        x: 120,
        rectWidth: 148,
      },
      C: {
        x: 120,
        rectWidth: 148,
      },
      D: {
        x: 120,
        rectWidth: 148,
      },
    },
    zoom: {
      A: {
        x: 50,
        rectWidth: 288,
      },
      B: {
        x: 50,
        rectWidth: 288,
      },
      C: {
        x: 50,
        rectWidth: 288,
      },
      D: {
        x: 50,
        rectWidth: 288,
      },
    },
  },
  flafa: {
    default: {
      A: {
        x: 130,
        rectWidth: 130,
      },
      B: {
        x: 130,
        rectWidth: 130,
      },
      C: {
        x: 130,
        rectWidth: 130,
      },
      D: {
        x: 130,
        rectWidth: 130,
      },
    },
    zoom: {
      A: {
        x: 60,
        rectWidth: 270,
      },
      B: {
        x: 60,
        rectWidth: 270,
      },
      C: {
        x: 60,
        rectWidth: 270,
      },
      D: {
        x: 60,
        rectWidth: 270,
      },
    },
  },
  "agb-1fa": {
    default: {
      A: {
        x: 130,
        rectWidth: 125,
      },
      B: {
        x: 130,
        rectWidth: 125,
      },
      C: {
        x: 130,
        rectWidth: 125,
      },
      D: {
        x: 130,
        rectWidth: 125,
      },
    },
    zoom: {
      A: {
        x: 70,
        rectWidth: 245,
      },
      B: {
        x: 70,
        rectWidth: 245,
      },
      C: {
        x: 70,
        rectWidth: 245,
      },
      D: {
        x: 80,
        rectWidth: 225,
      },
    },
  },
  "ff-1dfa": {
    default: {
      A: {
        x: 130,
        rectWidth: 125,
      },
      B: {
        x: 130,
        rectWidth: 125,
      },
      C: {
        x: 130,
        rectWidth: 125,
      },
      D: {
        x: 130,
        rectWidth: 125,
      },
    },
    zoom: {
      A: {
        x: 70,
        rectWidth: 245,
      },
      B: {
        x: 70,
        rectWidth: 245,
      },
      C: {
        x: 70,
        rectWidth: 245,
      },
      D: {
        x: 80,
        rectWidth: 225,
      },
    },
  },
  "u-4dfa": {
    default: {
      A: {
        x: 130,
        rectWidth: 125,
      },
      B: {
        x: 130,
        rectWidth: 125,
      },
      C: {
        x: 130,
        rectWidth: 125,
      },
      D: {
        x: 130,
        rectWidth: 125,
      },
    },
    zoom: {
      A: {
        x: 70,
        rectWidth: 245,
      },
      B: {
        x: 70,
        rectWidth: 245,
      },
      C: {
        x: 70,
        rectWidth: 245,
      },
      D: {
        x: 80,
        rectWidth: 225,
      },
    },
  },
  "abu-17d": {
    default: {
      A: {
        x: 130,
        rectWidth: 120,
      },
      B: {
        x: 130,
        rectWidth: 120,
      },
      C: {
        x: 130,
        rectWidth: 120,
      },
      D: {
        x: 130,
        rectWidth: 120,
      },
    },
    zoom: {
      A: {
        x: 65,
        rectWidth: 255,
      },
      B: {
        x: 65,
        rectWidth: 255,
      },
      C: {
        x: 65,
        rectWidth: 255,
      },
      D: {
        x: 65,
        rectWidth: 255,
      },
    },
    defaultBack: {
      A: {
        x: 133,
        rectWidth: 120,
      },
      B: {
        x: 133,
        rectWidth: 120,
      },
      C: {
        x: 133,
        rectWidth: 120,
      },
      D: {
        x: 133,
        rectWidth: 120,
      },
    },
    zoomBack: {
      A: {
        x: 70,
        rectWidth: 255,
      },
      B: {
        x: 70,
        rectWidth: 255,
      },
      C: {
        x: 70,
        rectWidth: 255,
      },
      D: {
        x: 70,
        rectWidth: 255,
      },
    },
  },
  "abu-86d": {
    default: {
      A: {
        x: 140,
        rectWidth: 120,
      },
      B: {
        x: 140,
        rectWidth: 120,
      },
      C: {
        x: 140,
        rectWidth: 120,
      },
      D: {
        x: 140,
        rectWidth: 120,
      },
    },
    zoom: {
      A: {
        x: 75,
        rectWidth: 235,
      },
      B: {
        x: 75,
        rectWidth: 235,
      },
      C: {
        x: 75,
        rectWidth: 235,
      },
      D: {
        x: 75,
        rectWidth: 235,
      },
    },
  },
  "bk-7d": {
    default: {
      A: {
        x: 130,
        rectWidth: 120,
      },
      B: {
        x: 130,
        rectWidth: 120,
      },
      C: {
        x: 130,
        rectWidth: 120,
      },
      D: {
        x: 130,
        rectWidth: 120,
      },
      E: {
        x: 130,
        rectWidth: 120,
      },
    },
    zoom: {
      A: {
        x: 75,
        rectWidth: 235,
      },
      B: {
        x: 75,
        rectWidth: 235,
      },
      C: {
        x: 75,
        rectWidth: 235,
      },
      D: {
        x: 75,
        rectWidth: 235,
      },
      E: {
        x: 75,
        rectWidth: 235,
      },
    },
  },
  "ce-53d": {
    default: {
      A: {
        x: 110,
        rectWidth: 160,
      },
      B: {
        x: 115,
        rectWidth: 160,
      },
    },
    zoom: {
      A: {
        x: 40,
        rectWidth: 300,
      },
      B: {
        x: 40,
        rectWidth: 300,
      },
    },
  },
  "wil-66d": {
    default: {
      A: {
        x: 130,
        rectWidth: 128,
      },
      B: {
        x: 130,
        rectWidth: 128,
      },
      C: {
        x: 130,
        rectWidth: 128,
      },
    },
    zoom: {
      A: {
        x: 60,
        rectWidth: 258,
      },
      B: {
        x: 60,
        rectWidth: 258,
      },
      C: {
        x: 60,
        rectWidth: 258,
      },
    },
  },
  "win-22": {
    default: {
      A: {
        x: 135,
        rectWidth: 115,
      },
      B: {
        x: 135,
        rectWidth: 115,
      },
      C: {
        x: 135,
        rectWidth: 115,
      },
      D: {
        x: 135,
        rectWidth: 115,
      },
    },
    zoom: {
      A: {
        x: 70,
        rectWidth: 245,
      },
      B: {
        x: 70,
        rectWidth: 245,
      },
      C: {
        x: 70,
        rectWidth: 245,
      },
      D: {
        x: 80,
        rectWidth: 225,
      },
    },
  },
};
