import { Box, styled } from "@mui/material";
import { SCROLL_CSS } from "../../../../constants/common";
import {
  OverallBody,
  OverallHeader,
  OverallHeaderCell,
  OverallOverlapCell,
  OverallTable,
} from "../styles";
import useAppSelector from "../../../../hooks/useSelector";
import TextTableHeader from "../../../UI/Typography/TextTableHeader";
import TextTableOptionTitle from "../../../UI/Typography/TextTableOptionTitle";
import TextTableOptionText from "../../../UI/Typography/TextTableOptionText";
import OverallColumnCell from "./OverallCoumnCell";
import icon from '../../../../assets/img/checkbox.svg';
import iconChecked from '../../../../assets/img/checkboxSelected.svg';
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  gap: 32px;
`;

const ScrollContainer = styled(Box)`
  width: 100%;
  height: 420px;
  margin-bottom: 16px;
  overflow: auto;
  flex-shrink: 0;

  ${SCROLL_CSS};
`;

const OverallTableLayout = () => {
  const { t } = useTranslation();
  const doors = useAppSelector((state) => state.config.doors);
  const groups = useAppSelector((state) => state.config.groups);

  return (
    <Wrapper>
      <ScrollContainer>
        <OverallTable>
          <OverallHeader>
            <tr>
              <OverallHeaderCell />
              {
                groups.map(item => (
                  <OverallHeaderCell
                    key={item.id}
                    sx={{ borderTop: '1px solid #E0E0E0' }}
                  >
                    <TextTableHeader
                      sx={{ textAlign: 'left', fontSize: '14px' }}
                    >
                      {item.name}
                    </TextTableHeader>

                    <Box
                      sx={{ display: 'flex' }}
                    >
                      <TextTableOptionTitle
                        sx={{ marginRight: '8px' }}
                      >
                        {t('summary.table.amount')}
                      </TextTableOptionTitle>

                      <TextTableOptionText>
                        {item.keysCount}
                      </TextTableOptionText>
                    </Box>
                  </OverallHeaderCell>
                ))
              }
            </tr>
          </OverallHeader>

          <OverallBody>
            {
              doors.map(door => (
                <tr key={door.id}>
                  <OverallColumnCell
                    name={door.name}
                    type={door.type}
                    langeAusen={door.langeAusen}
                    langeInnen={door.langeInnen}
                    bugelhohe={door.bugelhohe}
                  />
                  {
                    groups.map(group => (
                      <OverallOverlapCell
                        key={group.id}
                      >
                        {
                          group.doorIds.includes(door.id)
                            ? <img src={iconChecked} alt="(i)"/>
                            : <img src={icon} alt="(i)"/>
                        }
                      </OverallOverlapCell>
                    ))
                  }
                </tr>
              ))
            }
          </OverallBody>
        </OverallTable>
      </ScrollContainer>
    </Wrapper>
  );
};

export default OverallTableLayout;