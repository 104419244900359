import { SyntheticEvent, useMemo, createRef, useEffect } from "react";
import { TTabsData } from "../../../constants/common";
import { StyledTab, StyledTabs } from "../../UI/Tabs/styles";
import TabLabel from "../../UI/Tabs/TabLabel";
import LanguageDropdown from "../../UI/LanguageDropdown/LanguageDropdown";
import useAppSelector from "../../../hooks/useSelector";
import { LANGUAGE_LIST } from "../../../constants/language";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../store/languageSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  tabsData: TTabsData[];
  page: number;
  enabledTabIds: number[];
  onChangeTab: (_: SyntheticEvent<Element, Event>, newVal: number) => void;
  disablePagination: boolean;
  disableForward: boolean;
}

const TabSwitchPanel = ({
  tabsData,
  page,
  enabledTabIds,
  onChangeTab,
  disablePagination,
  disableForward,
}: IProps) => {
  const { t } = useTranslation();
  const language = useAppSelector((state) => state.language.value);

  const dispatch = useDispatch();

  const languageIcon = useMemo(() => {
    return LANGUAGE_LIST.find((lang) => lang.value === language)?.icon;
  }, [language]);

  const handleChangeLanguage = (value: string) => {
    dispatch(setLanguage(value));
  };

  return (
    <div>
      <StyledTabs value={page} onChange={onChangeTab}>
        {tabsData.map((item) => {
          const disabled =
            disablePagination ||
            !enabledTabIds.includes(item.id) ||
            (item.id === 5 && disableForward);
          return (
            <StyledTab
              isActive={page === item.id}
              key={item.id}
              label={
                <TabLabel
                  index={item.id}
                  isActive={page === item.id}
                  text={t(`pages.${item.text}`)}
                  disabled={disabled}
                />
              }
              value={item.id}
              disableRipple
              disabled={disabled}
            />
          );
        })}
        <LanguageDropdown
          value={language}
          icon={languageIcon}
          list={LANGUAGE_LIST}
          onChangeLanguage={handleChangeLanguage}
        />
      </StyledTabs>
    </div>
  );
};

export default TabSwitchPanel;
