import { Box, styled } from "@mui/material";

export const TurenCounterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 325px;
  gap: 32px;
`;

export const ProjectNameBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    width: 50%;
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
  }
`;
