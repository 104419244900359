import { ChangeEvent } from "react";
import FormInput from "../../../UI/FormInput";
import TextField from "../../../UI/TextField";
import useAppSelector from "../../../../hooks/useSelector";
import useAppDispatch from "../../../../hooks/useDispatch";
import {
  setContactEmail,
  setContactName,
  setCustomerNumber,
} from "../../../../store/contactSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  loading: boolean;
}
const ExistUser = ({ loading }: IProps) => {
  const { t } = useTranslation();
  const name = useAppSelector((state) => state.contact.name);
  const email = useAppSelector((state) => state.contact.email);
  const customerNumber = useAppSelector((state) => state.contact.customerNumber);

  const dispatch = useAppDispatch();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactName(e.target.value));
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactEmail(e.target.value));
  }

  const handleChangeCustomerNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 5) {
      return;
    }
    dispatch(setCustomerNumber(e.target.value));
  }

  return (
    <>
      <FormInput
        label={`${t('order.person.title')}*`}
      >
        <TextField
          value={name}
          onChange={handleChangeName}
          placeholder={t('order.person.placeholder')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label={`${t('order.customer-number')}*`}
      >
        <TextField
          value={customerNumber}
          onChange={handleChangeCustomerNumber}
          maxLength={5}
          type={'number'}
          placeholder={t('order.customer-number')}
          disabled={loading}
        />
      </FormInput>

      <FormInput
        label='E-Mail*'
      >
        <TextField
          value={email}
          type='email'
          onChange={handleChangeEmail}
          placeholder='E-Mail'
          disabled={loading}
        />
      </FormInput>
    </>
  )
};

export default ExistUser;