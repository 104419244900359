import { useTranslation } from "react-i18next";
import useAppDispatch from "../../hooks/useDispatch";
import useAppSelector from "../../hooks/useSelector";
import { setDeletionId, setKeyTypes } from "../../store/appKeysSlice";
import { ModalButtonsWrap } from "../Modals/styles";
import Button from "../UI/Button";
import Modal from "../UI/Modal/Modal";
import TextModalHeading from "../UI/Typography/TextModalHeading";
import TextRegularGrey from "../UI/Typography/TextRegularGrey";
import { IModalProps } from "./types";
import { useMemo } from "react";
import generateKeyArray from "../../tools/generateKeyArray";

const ModalDeleteKey = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const deletionId = useAppSelector((state) => state.appKeys.deletionId);
  const dispatch = useAppDispatch();

  const selectedKeysArray = useMemo(() => {
    return Object.values(keys).flat();
  }, [keys]);

  const onConfirm = () => {
    dispatch(setKeyTypes(generateKeyArray(selectedKeysArray.filter((item) => item.id !== deletionId))));
    dispatch(setDeletionId(null));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      maxWidth='480px'
      width='100%'
    >
      <TextModalHeading
        sx={{ marginBottom: '32px' }}
      >
        {t('modal.delete-key.title')}
      </TextModalHeading>

      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t('modal.delete-key.text')}
      </TextRegularGrey>

      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onClose}
          variant='second'
        >
          {t('modal.cancel')}
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          {t('modal.delete')}
        </Button>
      </ModalButtonsWrap>
    </Modal>
  )
};

export default ModalDeleteKey;