import {Dialog, styled} from "@mui/material";

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    background: none;
    border-radius: 24px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    max-width: 720px;
  }
`;

export default CustomDialog;
