import {styled, Typography} from "@mui/material";

const TextStepName = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #06326E;
`;

export default TextStepName;
