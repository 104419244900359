import {Box, Button, styled} from "@mui/material";

export const KeyCardImage = styled(Box)`
  width: 100%;
  margin-bottom: 24px;

  img {
    width: 100%;
    height: 376px;
    object-fit: none;
  }
`;

export const KeyCardSwitchButtonsBox = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
`;

export const KeyCardOptions = styled(Box)`
  width: 100%;
`;

export const KeyCardTextOptions = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 8px;
`;

export const KeyCardSize = styled(Box)`
  display: flex;
  padding-right: 8px;
`;

export const KeyCardSizeMinus = styled(Button)`
  width: 24px;
  height: 24px;
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 1.5px;
    background-color: #06326E;
    transform: translate(-50%, -50%);
    transition: background-color .2s ease-in;
  }

  &.disabled:after {
    background-color: rgba(6, 50, 110, .5);
  }
`;

export const KeyCardSizePlus = styled(Button)`
  width: 24px;
  height: 24px;
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 1.5px;
    background-color: #06326E;
    transform: translate(-50%, -50%);
    transition: background-color .2s ease-in;
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 1.5px;
    background-color: #06326E;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: background-color .2s ease-in;
  }

  &.disabled:after {
    background-color: rgba(6, 50, 110, .5);
  }

  &.disabled:before {
    background-color: rgba(6, 50, 110, .5);
  }
`;
