import {css} from "@mui/material";

export const HOME_SITE = 'https://basi.eu/';
export const SEND_CONFIG_TO_EMAIL = 'schliesssysteme@basi.eu';
export const PRIVACY_POLICY_LINK = 'https://basi.eu/datenschutzerklaerung/';
export const LOCK_BLUEPRINT_LINK = 'https://basi.eu/wp-content/uploads/2019/10/Der-Profilzylinder.pdf';
export const MAX_DOORS = 150;
export const MAX_GROUPS = 100;
export const MAX_KEYS = 1000;

export type TTabsData = {
  id: number;
  text: string;
}

export const TABS_DATA: TTabsData[] = [
  {
    id: 1,
    text: 'Schließsystem',
  },
  {
    id: 2,
    text: 'Projekt',
  },
  {
    id: 3,
    text: 'Türen',
  },
  {
    id: 4,
    text: 'Benutzer',
  },
  {
    id: 5,
    text: 'Schließplan',
  },
  {
    id: 6,
    text: 'Zusammenfassung'
  },
  {
    id: 7,
    text: 'Anfrage',
  },
];

export const TABS_KEYS_DATA: TTabsData[] = [
  {
    id: 1,
    text: 'Prägerohlinge',
  },
  {
    id: 2,
    text: 'Prägung',
  },
  {
    id: 3,
    text: 'Zusammenfassung',
  },
  {
    id: 4,
    text: 'Anfrage',
  },
];

export const SCROLL_CSS = css`
  &::-webkit-scrollbar {
    width: 8px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
`;
