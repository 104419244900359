import {IFinalKeysConfig} from "../types/common";
import useAppSelector from "./useSelector";

interface IReturnPrepareConfig {
  getConfig: () => IFinalKeysConfig;
}

const useKeysPrepareConfig = (): IReturnPrepareConfig => {
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const name = useAppSelector((state) => state.contact.name);
  const email = useAppSelector((state) => state.contact.email);
  const phone = useAppSelector((state) => state.contact.phone);
  const company = useAppSelector((state) => state.contact.company);
  const street = useAppSelector((state) => state.contact.street);
  const city = useAppSelector((state) => state.contact.city);
  const zip = useAppSelector((state) => state.contact.zip);
  const customerNumber = useAppSelector((state) => state.contact.customerNumber);
  const orderType = useAppSelector((state) => state.contact.orderType);
  const comment = useAppSelector((state) => state.contact.comment);
  const language = useAppSelector((state) => state.language.value);

  const getConfig = (): IFinalKeysConfig => {
    return {
      keys,
      name,
      email,
      phone,
      company,
      street,
      city,
      zip,
      comment,
      customerNumber,
      orderType,
      language,
    }
  }

  return {
    getConfig,
  }
}

export default useKeysPrepareConfig;
