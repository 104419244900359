import { useTranslation } from "react-i18next";
import Button from "../UI/Button";
import Modal from "../UI/Modal/Modal";
import TextModalHeading from "../UI/Typography/TextModalHeading";
import TextRegularGrey from "../UI/Typography/TextRegularGrey";
import { IModalProps } from "./types";
import useAppSelector from "../../hooks/useSelector";

const ModalMenge = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const mengeModalType = useAppSelector((state) => state.appKeys.mengeModalType);

  return (
    <Modal
      onClose={onClose}
      maxWidth='480px'
      width='100%'
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        {t('modal.menge.title')}
      </TextModalHeading>

      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t(`modal.menge.${mengeModalType}-text`)}
      </TextRegularGrey>

      <Button
        variant='second'
        sx={{
          marginTop: '32px',
        }}
        onClick={onClose}
      >
        {t('modal.close')}
      </Button>
    </Modal>
  )
};

export default ModalMenge;