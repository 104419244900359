import { MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)`
  background: white;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #666666;

  &.Mui-selected {
    color: #e20031;
    background: white;

    &:hover {
      background: #f3f3f3;
    }
  }

  &:hover {
    background: #f8f8f8;
  }
`;
