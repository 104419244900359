export interface ITurenZylinderType {
  value: string;
  name: string;
  showLange?: boolean;
  showBugelhohe?: boolean;
  showHebelvariationen?: boolean;
}

export enum EZylinderTypesValues {
  DOPPELZYLIDER1 = 'doppelzylinder1',
  DOPPELZYLIDER2 = 'doppelzylinder2',
  DOPPELZYLIDER3 = 'doppelzylinder3',
  KNAUFZYLIDER = 'knaufzylinder',
  HALBZYLINDER = 'halbzylinder',
  HEBELZYLINDER = 'hebelzylinder',
  AUSENZYLINDER = 'ausenzylinder',
  VORHANGSCHLOSS = 'vorhangschloss',
}

export const TUREN_ZYLINDER_TYPES: ITurenZylinderType[] = [
  {
    value: EZylinderTypesValues.DOPPELZYLIDER1,
    name: 'Doppelzylinder ohne Not- und Gefahrenfunktion',
    showLange: true,
  },
  {
    value: EZylinderTypesValues.DOPPELZYLIDER2,
    name: 'Doppelzylinder mit Not- und Gefahrenfunktion',
    showLange: true,
  },
  {
    value: EZylinderTypesValues.DOPPELZYLIDER3,
    name: 'Doppelzylinder mit Freilauffunktion',
    showLange: true,
  },
  {
    value: EZylinderTypesValues.KNAUFZYLIDER,
    name: 'Knaufzylinder',
    showLange: true,
  },
  {
    value: EZylinderTypesValues.HALBZYLINDER,
    name: 'Halbzylinder',
    showLange: true,
  },
  {
    value: EZylinderTypesValues.HEBELZYLINDER,
    name: 'Hebelzylinder',
    showHebelvariationen: true,
  },
  {
    value: EZylinderTypesValues.AUSENZYLINDER,
    name: 'Außenzylinder',
  },
  {
    value: EZylinderTypesValues.VORHANGSCHLOSS,
    name: 'Vorhangschloss (VHS)',
    showBugelhohe: true,
  }
];

export type TLanges = 'ausen' | 'innen' | 'knaufseite';

export interface ITurenLange {
  value: string;
  name: string;
}

export const TUREN_LANGES: ITurenLange[] = [
  {
    value: '10',
    name: '10 mm',
  },
  {
    value: '30',
    name: '30 mm',
  },
  {
    value: '35',
    name: '35 mm',
  },
  {
    value: '40',
    name: '40 mm',
  },
  {
    value: '45',
    name: '45 mm',
  },
  {
    value: '50',
    name: '50 mm',
  },
  {
    value: '55',
    name: '55 mm',
  },
  {
    value: '60',
    name: '60 mm',
  },
  {
    value: '65',
    name: '65 mm',
  },
  {
    value: '70',
    name: '70 mm',
  },
  {
    value: '75',
    name: '75 mm',
  },
  {
    value: '80',
    name: '80 mm',
  },
  {
    value: '85',
    name: '85 mm',
  },
  {
    value: '90',
    name: '90 mm',
  },
  {
    value: 'K30',
    name: 'K30 mm',
  },
  {
    value: 'K35',
    name: 'K35 mm',
  },
  {
    value: 'K40',
    name: 'K40 mm',
  },
  {
    value: 'K45',
    name: 'K45 mm',
  },
  {
    value: 'K50',
    name: 'K50 mm',
  },
  {
    value: 'K55',
    name: 'K55 mm',
  },
  {
    value: 'K60',
    name: 'K60 mm',
  },
  {
    value: 'K65',
    name: 'K65 mm',
  },
  {
    value: 'K70',
    name: 'K70 mm',
  },
  {
    value: 'K75',
    name: 'K75 mm',
  },
  {
    value: 'K80',
    name: 'K80 mm',
  },
  {
    value: 'K85',
    name: 'K85 mm',
  },
  {
    value: 'K90',
    name: 'K90 mm',
  },
  {
    value: '31',
    name: '31 mm',
  },
  {
    value: '36',
    name: '36 mm',
  },
  {
    value: '41',
    name: '41 mm',
  },
  {
    value: '46',
    name: '46 mm',
  },
  {
    value: '51',
    name: '51 mm',
  },
  {
    value: '56',
    name: '56 mm',
  },
  {
    value: '61',
    name: '61 mm',
  },
  {
    value: '66',
    name: '66 mm',
  },
  {
    value: '71',
    name: '71 mm',
  },
  {
    value: '76',
    name: '76 mm',
  },
  {
    value: '81',
    name: '81 mm',
  },
  {
    value: '86',
    name: '86 mm',
  },
  {
    value: '91',
    name: '91 mm',
  },
  {
    value: 'K31',
    name: 'K31 mm',
  },
  {
    value: 'K36',
    name: 'K36 mm',
  },
  {
    value: 'K41',
    name: 'K41 mm',
  },
  {
    value: 'K46',
    name: 'K46 mm',
  },
  {
    value: 'K51',
    name: 'K51 mm',
  },
  {
    value: 'K56',
    name: 'K56 mm',
  },
  {
    value: 'K61',
    name: 'K61 mm',
  },
  {
    value: 'K66',
    name: 'K66 mm',
  },
  {
    value: 'K71',
    name: 'K71 mm',
  },
  {
    value: 'K76',
    name: 'K76 mm',
  },
  {
    value: 'K81',
    name: 'K81 mm',
  },
  {
    value: 'K86',
    name: 'K86 mm',
  },
  {
    value: 'K91',
    name: 'K91 mm',
  },
];

export const BUGELHOHE_TUREN_LANGES: ITurenLange[] = [
  {
    value: '30',
    name: '30 mm',
  },
  {
    value: '40',
    name: '40 mm',
  },
  {
    value: '50',
    name: '50 mm',
  },
  {
    value: '80',
    name: '80 mm',
  },
];

export const K6_TUREN_VARIANTS: ITurenLange[] = [
  {
    value: 'k6-standart',
    name: 'Standard: Materialstärke 3 mm, Breite 20 mm, Länge 49,5 mm, 14,5 mm gekröpft',
  },
  {
    value: 'k6-variante1',
    name: 'Variante 1: Materialstärke 3 mm, Breite 20 mm, Länge 44,5 mm, 10,5 mm gekröpft',
  },
  {
    value: 'k6-variante2',
    name: 'Variante 2: Materialstärke 3 mm, Breite 20 mm, Länge 48 mm, 11 mm gekröpft',
  },
  {
    value: 'k6-variante3',
    name: 'Variante 3: Materialstärke 3 mm, Breite 20 mm, Länge 52 mm, 10,5 mm gekröpft',
  },
  {
    value: 'k6-variante4',
    name: 'Variante 4: Materialstärke 3 mm, Breite 20 mm, Länge 54 mm, 13 mm gekröpft',
  },
  {
    value: 'k6-variante5',
    name: 'Variante 5: Materialstärke 3 mm, Breite 20 mm, Länge 65 mm, flach',
  },
];

export const T250_TUREN_VARIANTS: ITurenLange[] = [
  {
    value: 't250-standart',
    name: 'Standard: Materialstärke 2 mm, Breite 16 mm, Länge 32,5 mm, 16,5mm gekröpft',
  },
  {
    value: 't250-variante1',
    name: 'Variante 1: Materialstärke 2,5 mm, Breite 13 mm, Länge 43 mm, flach',
  },
];

export const K10_TUREN_VARIANTS: ITurenLange[] = [
  {
    value: 'k10-standart',
    name: 'Standard: Materialstärke 3 mm, Breite 20 mm, Länge 49,5 mm, 14,5 mm gekröpft',
  },
  {
    value: 'k10-variante1',
    name: 'Variante 1: Materialstärke 3 mm, Breite 20 mm, Länge 44,5 mm, 10,5 mm gekröpft',
  },
  {
    value: 'k10-variante2',
    name: 'Variante 2: Materialstärke 3 mm, Breite 20 mm, Länge 48 mm, 11 mm gekröpft',
  },
  {
    value: 'k10-variante3',
    name: 'Variante 3: Materialstärke 3 mm, Breite 20 mm, Länge 52 mm, 10,5 mm gekröpft',
  },
  {
    value: 'k10-variante4',
    name: 'Variante 4: Materialstärke 3 mm, Breite 20 mm, Länge 54 mm, 13 mm gekröpft',
  },
  {
    value: 'k10-variante5',
    name: 'Variante 5: Materialstärke 3 mm, Breite 20 mm, Länge 65 mm, flach',
  },
];

export const LANGES_STANDARD_FILTER = ['30', '40', '50'];
