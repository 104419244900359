import { IKeysBlankNames } from "./pragerohlinge";

type IImageCanvasPositions = {
  [blank: string]: {
    x: number;
    y: number;
    backX?: number;
    backY?: number;
  };
};

export const LOGO_CANVAS_POSITIONS: IImageCanvasPositions = {
  [IKeysBlankNames.FA]: {
    x: 196,
    y: 230,
  },
  [IKeysBlankNames.OV]: {
    x: 196,
    y: 214,
  },
  "basi-bmfa": {
    x: 200,
    y: 191,
  },
  "basi-vbfa": {
    x: 196,
    y: 215,
  },
  "abu-91fa": {
    x: 196,
    y: 180,
  },
  "basi-vfa": {
    x: 196,
    y: 220,
  },
  "ci-48fa": {
    x: 210,
    y: 190,
    backX: 170,
    backY: 190,
  },
  "is-15fa": {
    x: 135,
    y: 220,
    backX: 144,
    backY: 220,
  },
  flafa: {
    x: 196,
    y: 220,
  },
  "agb-1fa": {
    x: 193,
    y: 200,
  },
  "ff-1dfa": {
    x: 193,
    y: 200,
  },
  "u-4dfa": {
    x: 193,
    y: 205,
  },
  "abu-17d": {
    x: 192,
    y: 230,
    backX: 200,
    backY: 230,
  },
  "abu-86d": {
    x: 193,
    y: 225,
  },
  "bk-7d": {
    x: 193,
    y: 230,
  },
  "ce-53d": {
    x: 191,
    y: 230,
  },
  "wil-66d": {
    x: 190,
    y: 225,
  },
  "win-22": {
    x: 193,
    y: 195,
  },
};
