import { Box, styled } from "@mui/material";

export const ImageLanguageBox = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TextLanguageBox = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06326E;
`;

export const WrapperLanguageInner = styled(Box)`
  display: flex;
  height: 100%;
  padding: 8px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;