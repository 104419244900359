import { Box, Button, IconButton, styled } from "@mui/material";
import {
  IKeySideFont,
  IKeySideLogoSize,
  IKeySideName,
  IKeyTextSize,
  IKeyTextType,
  IKeyType,
  IKeysModalsCommon,
} from "../../../../../store/keysTypes";
import TextCardTitle from "../../../../UI/Typography/TextCardTitle";
import {
  KeyCardImage,
  KeyCardOptions,
  KeyCardSize,
  KeyCardSizeMinus,
  KeyCardSizePlus,
  KeyCardSwitchButtonsBox,
  KeyCardTextOptions,
} from "./styles";
import SwitchButtons from "../../../../UI/SwitchButtons";
import {
  KEY_ENGRAVING_TYPES,
  KEY_FONT_TYPES,
  KEY_LOGO_SIZES,
} from "../../../../../constants/pragung";
import FormInput from "../../../../UI/FormInput";
import TextField from "../../../../UI/TextField";
import { ChangeEvent, useMemo, useState } from "react";
import TextLabel from "../../../../UI/Typography/TextLabel";
import Select from "../../../../UI/Select";
import TextCardDescription from "../../../../UI/Typography/TextCardDescription";
import FileLoader from "../../../../UI/FileLoader/FileLoader";
import svgTooltip from "../../../../../assets/img/tooltip.svg";
import ImageFrame from "../../../../UI/ImageFrame/ImageFrame";
import TextLabelBlue from "../../../../UI/Typography/TextLabelBlue";
import { IKeysBlankNames } from "../../../../../constants/pragerohlinge";
import { useTranslation } from "react-i18next";
import getKeyImage from "../../../../../tools/getGetKeyImage";
import resizeImage from "../../../../../tools/resizeImage";
import useGetKeyFontSize from "../../../../../hooks/useGetKeyFontSize";

const Wrapper = styled(Box)`
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 16px;
`;

type IProps = {
  title: string;
  type: IKeyType;
  text: IKeyTextType[];
  font: IKeySideFont;
  size: IKeyTextSize;
  logo: string;
  blank: string;
  profile: string;
  logoSize: IKeySideLogoSize;
  side: IKeySideName;
  onChangeType?: (value: string) => void;
  onChangeTextField?: (value: string, index: number) => void;
  onChangeFont?: (value: string) => void;
  onChangeSize?: (name: string, value: number) => void;
  onChangeLogo?: (value: string) => void;
  onChangeLogoSize?: (value: IKeySideLogoSize) => void;
  onOpenModal?: (value: IKeysModalsCommon) => void;
  onScreenshot: (value: string) => void;
};

const KeyCard = ({
  title,
  type,
  text,
  font,
  size,
  blank,
  profile,
  logo,
  logoSize,
  side,
  onChangeType,
  onChangeTextField,
  onChangeFont,
  onChangeSize,
  onChangeLogo,
  onChangeLogoSize,
  onOpenModal,
  onScreenshot,
}: IProps) => {
  const [focus, setFocus] = useState(false);
  const { image } = getKeyImage({ profile, blank });
  const { t } = useTranslation();
  const { minFontSize, maxFontSize } = useGetKeyFontSize();

  const getBlank = useMemo(() => {
    if (IKeysBlankNames.FA === blank || IKeysBlankNames.OV === blank) {
      return blank;
    }
    return profile;
  }, [blank, profile]);

  const handleChangeType = (value: string) => {
    if (onChangeType) {
      onChangeType(value);
    }
  };

  const handleChangeKeyName = (value: string, index: number, name: string) => {
    if (onChangeTextField) {
      const fieldMaxFontSize = maxFontSize(name, value.length, getBlank);
      onChangeTextField(value, index);

      if (size[name] > fieldMaxFontSize && onChangeSize) {
        onChangeSize(name, fieldMaxFontSize);
      }
    }
  };

  const handleChangeFont = (value: string) => {
    if (onChangeFont) {
      onChangeFont(value);
    }
  };

  const handleChangeSize = (name: string, value: number) => {
    if (!onChangeSize) {
      return;
    }

    onChangeSize(name, value);
  };

  const handleChangeFile = async (file: File | null) => {
    if (!onChangeLogo) {
      return;
    }
    const reader = new FileReader();
    if (file) {
      const imageBlob =
        file?.type === "image/svg+xml"
          ? file
          : ((await resizeImage(file)) as Blob);
      reader.readAsDataURL(imageBlob);
      reader.onloadend = function () {
        if (reader.result) {
          onChangeLogo(reader.result as string);
        }
      };
      return;
    }
    onChangeLogo("");
  };

  const handleOpenModal = (value: IKeysModalsCommon) => {
    if (!onOpenModal) {
      return;
    }
    onOpenModal(value);
  };

  const handleChangeLogoSize = (value: string) => {
    if (!onChangeLogoSize) {
      return;
    }

    const currentLogoSizeIndex = KEY_LOGO_SIZES.findIndex(
      (item) => item.value === logoSize
    );
    if (value === "inc") {
      if (currentLogoSizeIndex === KEY_LOGO_SIZES.length - 1) {
        return;
      }
      onChangeLogoSize(KEY_LOGO_SIZES[currentLogoSizeIndex + 1].value);
    }

    if (value === "dec") {
      if (currentLogoSizeIndex === 0) {
        return;
      }
      onChangeLogoSize(KEY_LOGO_SIZES[currentLogoSizeIndex - 1].value);
    }
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const handleScreenshot = (value: string) => {
    onScreenshot(value);
  };

  return (
    <Wrapper>
      <TextCardTitle>{title}</TextCardTitle>
      <KeyCardImage>
        <ImageFrame
          image={image}
          focus={focus}
          text={text}
          blank={getBlank}
          logo={logo}
          font={font}
          size={size}
          type={type}
          logoSize={logoSize}
          side={side}
          onScreenshot={handleScreenshot}
        />
      </KeyCardImage>

      <KeyCardSwitchButtonsBox>
        <SwitchButtons
          currentValue={type}
          onChange={handleChangeType}
          options={KEY_ENGRAVING_TYPES}
          translation={true}
          buttonWidth={131}
        />
      </KeyCardSwitchButtonsBox>

      <KeyCardOptions>
        {type === IKeyType.TEXT && (
          <>
            {/* <div>
              <TextLabel
                sx={{ marginBottom: '8px' }}
              >
                Beschriftung. Nicht mehr als 16 Zeichen
                <IconButton
                  disableRipple
                  onClick={() => handleOpenModal(IKeysModalsCommon.MODAL_LOGO)}
                  sx={{
                    padding: '2px',
                    marginLeft: '8px'
                  }}
                >
                  <img
                    src={svgTooltip}
                    alt='(i)'
                  />
                </IconButton> 
              </TextLabel>
            </div> */}
            <KeyCardOptions sx={{ marginBottom: "16px" }}>
              {text.map((item, index) => (
                <Box
                  key={item.name}
                  sx={{ ":not(:last-child)": { marginBottom: "8px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0 8px",
                      ":not(:last-child)": { marginBottom: "8px" },
                    }}
                  >
                    <TextLabelBlue>{item.name}</TextLabelBlue>
                    <FormInput label={""} sx={{ gap: 0 }}>
                      <TextField
                        value={item.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeKeyName(e.target.value, index, item.name)
                        }
                        onFocus={onFocus}
                        onBlur={onBlur}
                        maxLength={item.maxLength}
                      />
                    </FormInput>
                  </Box>
                  <TextCardDescription
                    sx={{
                      textAlign: "left",
                      fontSize: "12px",
                      lineHeight: "16px",
                      ":not(:last-child)": { marginBottom: "8px" },
                    }}
                  >
                    {`${t("embossing.text.label")} ${item.maxLength} ${t(
                      "embossing.text.sign"
                    )}`}
                  </TextCardDescription>

                  <KeyCardTextOptions
                    sx={{ justifyContent: "flex-end", height: "40px" }}
                  >
                    <KeyCardSize>
                      <KeyCardSizeMinus
                        sx={{ minWidth: "unset" }}
                        className={
                          size[item.name] <= minFontSize() ? "disabled" : ""
                        }
                        onClick={() =>
                          handleChangeSize(item.name, size[item.name] - 1)
                        }
                      />
                      <TextCardDescription sx={{ padding: "0 8px" }}>
                        {size[item.name]}
                      </TextCardDescription>
                      <KeyCardSizePlus
                        sx={{ minWidth: "unset" }}
                        className={
                          size[item.name] >=
                          maxFontSize(item.name, item.value.length, getBlank)
                            ? "disabled"
                            : ""
                        }
                        onClick={() =>
                          handleChangeSize(item.name, size[item.name] + 1)
                        }
                      />
                    </KeyCardSize>
                  </KeyCardTextOptions>
                </Box>
              ))}
            </KeyCardOptions>

            <KeyCardTextOptions>
              <FormInput
                label=""
                sx={{
                  maxWidth: "unset",
                  width: "auto",
                  gap: "unset",
                }}
              >
                <Select
                  options={KEY_FONT_TYPES}
                  value={font}
                  background={"transparent"}
                  bordercolor={"transparent"}
                  onChange={(e) => handleChangeFont(e.target.value as string)}
                />
              </FormInput>

              {/* <KeyCardSize>
                <KeyCardSizeMinus
                  sx={{ minWidth: 'unset' }}
                  onClick={() => handleChangeSize(item.name, size - 1)}
                />
                <TextCardDescription
                  sx={{ padding: '0 8px' }}
                >
                  {size}
                </TextCardDescription>
                <KeyCardSizePlus
                  sx={{ minWidth: 'unset' }}
                  onClick={() => handleChangeSize(item.name, size + 1)}
                />
              </KeyCardSize> */}
            </KeyCardTextOptions>
          </>
        )}
        {type === IKeyType.LOGO && (
          <>
            <TextLabel sx={{ marginBottom: "8px" }}>
              {t("embossing.logo.title")}
              <IconButton
                disableRipple
                onClick={() => handleOpenModal(IKeysModalsCommon.MODAL_LOGO)}
                sx={{
                  padding: "2px",
                  marginLeft: "8px",
                }}
              >
                <img src={svgTooltip} alt="(i)" />
              </IconButton>
            </TextLabel>

            <div style={{ marginBottom: "16px" }}>
              {blank !== IKeysBlankNames.ORIGINAL && (
              <div style={{ padding: "16px 0" }}>
                <div>{t("selectStandartLogo")}</div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {["pre-1", "pre-2", "pre-3", "pre-4"].map((item, index) => (
                    <Button
                      key={index}
                      onClick={async () => {
                        const res = await fetch(`/${item}.png`);
                        const blob = await res.blob();

                        const file = new File([blob], `${item}.png`, {
                          type: "image/png",
                        });

                        handleChangeFile(file);
                      }}
                    >
                      <img
                        alt="(i)"
                        style={{ width: "64px", objectFit: "contain" }}
                        src={`/${item}.png`}
                      />
                    </Button>
                  ))}
                </div>
              </div>
              )}

              <div style={{ height: "16px" }}></div>
              {blank === IKeysBlankNames.ORIGINAL ? (
                <div>{t("uploadYourLogo")}</div>
              ):(
                <div>{t("orUploadYourLogo")}</div>
              )}
              <div style={{ height: "8px" }}></div>
              <FileLoader
                file={logo}
                text={t("embossing.logo.upload-button")}
                onChangeFile={handleChangeFile}
              />
            </div>

            {!logo && <TextLabel>{t("embossing.logo.description")}</TextLabel>}

            {logo && (
              <KeyCardTextOptions
                sx={{
                  height: "40px",
                }}
              >
                <KeyCardSize>
                  <KeyCardSizeMinus
                    sx={{ minWidth: "unset" }}
                    onClick={() => handleChangeLogoSize("dec")}
                  />
                  <TextCardDescription sx={{ padding: "0 8px" }}>
                    {t(`embossing.logo.size.${logoSize}`)}
                  </TextCardDescription>
                  <KeyCardSizePlus
                    sx={{ minWidth: "unset" }}
                    onClick={() => handleChangeLogoSize("inc")}
                  />
                </KeyCardSize>
              </KeyCardTextOptions>
            )}
          </>
        )}
      </KeyCardOptions>
    </Wrapper>
  );
};

export default KeyCard;
