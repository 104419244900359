import { useMemo } from "react";
import { KEY_FONT_SIZES } from "../constants/keyFontSizes";
import { IKeysBlankNames } from "../constants/pragerohlinge";

const useGetKeyFontSize = () => {
  const maxFontSize = useMemo(() => {
    return (name: string, length: number, blank: string|IKeysBlankNames) => {
      const {
        startDecrease,
        defaultMax,
      } = KEY_FONT_SIZES[blank][name];

      if (length >= startDecrease) {
        return defaultMax - Math.floor((length - startDecrease) / 1.5) < 18 ? 18 : defaultMax - Math.floor((length - startDecrease) / 1.5);
      }

      return defaultMax;
    }
  }, []);

  const minFontSize = useMemo(() => {
    return () => {
      return 18;
    }
  }, []);

  return {
    maxFontSize,
    minFontSize,
  }
};

export default useGetKeyFontSize;