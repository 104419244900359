import { useMemo } from "react";
import useAppSelector from "./useSelector";

interface IValidationReturn {
  getValidation: boolean;
}

const useValidation = (): IValidationReturn => {
  const name = useAppSelector((state) => state.contact.name);
  const email = useAppSelector((state) => state.contact.email);
  const phone = useAppSelector((state) => state.contact.phone);
  const company = useAppSelector((state) => state.contact.company);
  const street = useAppSelector((state) => state.contact.street);
  const city = useAppSelector((state) => state.contact.city);
  const zip = useAppSelector((state) => state.contact.zip);
  const customerNumber = useAppSelector((state) => state.contact.customerNumber);
  const orderType = useAppSelector((state) => state.contact.orderType);

  const getValidation = useMemo(() => {
    if (orderType === 'Order') {
      console.log(customerNumber)
      return !!name.trim() && !!email.trim() && customerNumber.length === 5;
    }
  
    return !!name.trim() && !!company.trim() && !!email.trim() && !!phone.trim() && !!street.trim() && !!city.trim() && !!zip.trim();
  }, [name, email, phone, company, street, city, zip, customerNumber, orderType]);

  return {
    getValidation,
  }
};

export default useValidation;
