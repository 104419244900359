import { Box, keyframes, styled } from "@mui/material";

interface IProps {
  loading: boolean;
}

const Wrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 100;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const CircleLoader = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #06326E;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  animation: ${spin} 1s infinite ease;
`

export default function Loader ({
  loading,
}: IProps) {
  return (
    <>
      {
        loading &&
        <Wrapper>
          <CircleLoader />
        </Wrapper>
      }
    </>
  )
}