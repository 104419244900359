import TextStepName from "../../UI/Typography/TextStepName";
import OverallTableLayout from "./components/OverallTableLayout";
import FormInput from '../../UI/FormInput';
import TextArea from '../../UI/TextArea';
import { setComment } from '../../../store/contactSlice';
import {Box, styled} from "@mui/material";
import useAppDispatch from "../../../hooks/useDispatch";
import useAppSelector from "../../../hooks/useSelector";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)`
`;

const Zusammenfassung = () => {
  const { t } = useTranslation();
  const comment = useAppSelector((state) => state.contact.comment);

  const dispatch = useAppDispatch();

  const handleChangeComment = (value: string) => {
    dispatch(setComment(value));
  }

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('summary.title')}
      </TextStepName>
      <Wrapper>
        <OverallTableLayout />
        <Box
          sx={{ width: '100%', marginBottom: '60px' }}
        >
          <FormInput
            label=''
            sx={{ gap: 'unset' }}
          >
            <TextArea
              value={comment}
              minRows={5}
              maxRows={5}
              placeholder={t('order.comment')}
              onChange={(e) => handleChangeComment(e.target.value as string)}
            />
          </FormInput>
        </Box>
      </Wrapper>
    </div>
  )
}

export default Zusammenfassung;