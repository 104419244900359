import { Box, styled } from "@mui/material";

export const AnfrageContactsBox = styled(Box)`
  width: 480px;
  margin: 0 auto 16px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const AnfrageContactUsBox = styled(Box)`
  text-align: center;
  margin: 16px 0 32px;
`;

export const AnfrageSwitch = styled(Box)`
  margin-bottom: 32px;
`;
