import deFlag from '../assets/img/de-flag.svg';
import enFlag from '../assets/img/en-flag.svg';

export type ILanguageItem = {
  value: string;
  icon: string;
  text: string;
}

export enum LanguageValues {
  DE = 'DE',
  EN = 'EN',
}

export const LANGUAGE_LIST: ILanguageItem[] = [
  {
    value: LanguageValues.DE,
    icon: deFlag,
    text: 'Deutsch',
  },
  {
    value: LanguageValues.EN,
    icon: enFlag,
    text: 'English',
  }
];