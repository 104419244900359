import React from 'react';
import {IconButton} from "@mui/material";
import svgClose from "../../../assets/img/close.svg";

interface IProps {
  onClick: () => void;
}

const ButtonClose = ({onClick}: IProps) => {
  return (
    <IconButton
      disableRipple
      sx={{ padding: 0 }}
      onClick={onClick}
    >
      <img
        src={svgClose}
        alt='x'
      />
    </IconButton>
  );
};

export default ButtonClose;
