import {Box, styled} from "@mui/material";

export const ModalButtonsWrap = styled(Box)`
  margin-top: 32px;
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const ModalEditGroupLayout = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 296px 1fr;
  gap: 32px;
  margin-bottom: 58px;
`;
