import {Box, styled} from "@mui/material";

const CheckboxWrap = styled(Box)`
  display: flex;
  align-items: center;

  .MuiTypography-root {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    word-break: break-word;
  }
`;

export default CheckboxWrap;
