import React, {ChangeEvent, useState} from 'react';
import {styled, FormControlLabel} from "@mui/material";
import CheckboxElement from "./CheckboxElement";

const Wrapper = styled(FormControlLabel)`
  max-width: fit-content;
  user-select: none;
  
  .MuiTypography-root {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    word-break: break-word;
  }
`;

interface IProps {
  checked: boolean;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CheckboxLayout = ({ checked, label, onChange, disabled }: IProps) => {
  const [hover, setHover] = useState<boolean>();

  return (
    <Wrapper
      control={<CheckboxElement
        myhover={String(hover)}
        checked={checked}
        onChange={onChange}
      />}
      label={label}
      onMouseOver={() => !disabled && setHover(true)}
      onMouseOut={() => setHover(false)}
      disabled={disabled}
    />
  );
};

export default CheckboxLayout;
