import { useEffect, useState } from "react";
import useAppSelector from "../../../../hooks/useSelector";
import Card from "../../../UI/Card";
import { Box, styled } from "@mui/material";
import useAppDispatch from "../../../../hooks/useDispatch";
import { setModalName, setSelectedGroupId } from "../../../../store/appSlice";
import { MAX_GROUPS, SCROLL_CSS } from "../../../../constants/common";
import AddCardButton from "../../../UI/Card/AddCardButton";
import {
  addGroup,
  setDraftGroup,
  setGroupVisited,
  copyGroup,
  setConfigGroups,
} from "../../../../store/configSlice";
import { v4 } from "uuid";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import drag from "../../../../assets/img/drag.svg";
import generateGroups from "../../../../tools/generateGroups";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

const Wrapper = styled(Box)`
  min-height: 360px;
  max-height: 54vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;

  .draggable-item {
    display: flex;
  }

  .draggable-item__drag {
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
  }

  ${SCROLL_CSS};
`;

const GroupSelector = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string | number>(1);
  const groups = useAppSelector((state) => state.config.groups);
  const selectedId = useAppSelector((state) => state.app.selectedGroupId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedId) {
      dispatch(setSelectedGroupId(groups[0]?.id || null));
      return;
    }
  }, [selectedId]);

  useEffect(() => {
    if (+amount > MAX_GROUPS - groups.length) {
      setAmount(MAX_GROUPS - groups.length);
    }
  }, [groups]);

  const handleGroupSelect = (id: string) => {
    const groupIndex = groups.findIndex((k) => k.id === id);
    dispatch(setSelectedGroupId(id));
    dispatch(
      setGroupVisited({
        groupIndex,
        visited: true,
      })
    );
  };

  const handleRemoveGroup = () => {
    dispatch(setModalName("removeGroup"));
  };

  const handleAddGroup = () => {
    if (+amount <= 0) {
      return;
    }

    dispatch(setDraftGroup(null));

    if (amount === 1) {
      const newId = v4();
      dispatch(setSelectedGroupId(newId));
      dispatch(
        addGroup({
          visited: true,
          id: newId,
        })
      );
      return;
    }

    const newGroupsArray = generateGroups(+amount, groups);
    dispatch(setConfigGroups(newGroupsArray));
    dispatch(setSelectedGroupId(newGroupsArray[newGroupsArray.length - 1].id));
    dispatch(
      setGroupVisited({
        groupIndex: newGroupsArray.length - 1,
        visited: true,
      })
    );
  };

  const handleCopyGroup = (groupIndex: number) => {
    if (groups.length >= MAX_GROUPS) {
      return;
    }
    dispatch(
      copyGroup({
        newGroupId: v4(),
        groupIndex,
      })
    );
  };

  const handleMouseEnter = () => {
    if (!amount) {
      setAmount(1);
    }
  };

  const handleMouseLeave = () => {
    if (!amount) {
      setAmount(1);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = [...groups];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    dispatch(setConfigGroups(reorderedItems));
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {groups.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id} index={index}>
                  {(provided) => {
                    return (
                      <div
                        className="draggable-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Card
                          key={group.id}
                          title={group.name}
                          isSelected={group.id === selectedId}
                          isVisited={group.visited}
                          isEntered={true}
                          onClick={() => handleGroupSelect(group.id)}
                          onClickRemove={handleRemoveGroup}
                          onClickCopy={() => handleCopyGroup(index)}
                          id={`cardId_${group.id}`}
                        />
                        <Tooltip title={t("controls.drag")} arrow>
                          <div
                            className="draggable-item__drag"
                            {...provided.dragHandleProps}
                          >
                            <img src={drag} alt="drag" />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddCardButton
        onAdd={handleAddGroup}
        labelText={t("add-button")}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={groups.length >= MAX_GROUPS}
      />
    </Wrapper>
  );
};

export default GroupSelector;
