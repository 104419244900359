import {useEffect, useMemo, useState} from 'react';
import {Box, styled} from "@mui/material";
import {IProduktCard} from "../../../../constants/produkt";
import imgCircle from '../../../../assets/img/circle.svg';
import imgCircleHover from '../../../../assets/img/circleHover.svg';
import imgCircleActive from '../../../../assets/img/circleActive.svg';
import AppLinkParagraph from "../../../UI/Link/AppLinkParagraph";
import usePaginator from "../../../../hooks/usePaginator";
import SwitchButtons from "../../../UI/SwitchButtons";
import { PRODUCT_TYPES } from '../../../../constants/produkt';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
  isselected: string;
}

const Wrapper = styled(Box)`
  width: 100%;
  height: 372px;
`;

const CardBox = styled(Box)<WrapperProps>`
  width: 100%;
  height: 340px;
  background: #FFFFFF;
  border-radius: 16px;
  border: 2px solid ${props => props.isselected === 'true' ? '#E20031' : 'white'};
  cursor: pointer;
  padding: 8px;
  position: relative;
  margin-bottom: 8px;
`;

const LinkBox = styled(Box)`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CircleBox = styled(Box)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 8px;
  top: 8px;
`;

interface WrapperImageBox {
  src: string;
}

const ImageBox = styled(Box)<WrapperImageBox>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;

const SwitchButtonsBox = styled(Box)`
  width: 100%;
  padding: 0 16px;
`;

interface IProps {
  data: IProduktCard;
  isSelected: boolean;
  currentProductType?: string|null; 
  onClick: (type: string) => void;
}

const ProduktCard = ({ data, isSelected, onClick, currentProductType }: IProps) => {
  const [hover, setHover] = useState(false);
  const { onDownloadPdf } = usePaginator();
  const [productType, setProductType] = useState('werksprofil');
  const { t } = useTranslation();

  useEffect(() => {
    if (isSelected) {
      setProductType(currentProductType || 'werksprofil');
    }
  }, [currentProductType]);

  const getCircleImg = () => {
    if (isSelected) {
      return imgCircleActive;
    }

    if (hover) {
      return imgCircleHover;
    }

    return imgCircle;
  }

  const getCardImage = useMemo(() => {
    if (productType === 'sonderprofil') {
      return data.img[1];
    }
    return data.img[0];
  }, [productType]);

  const handleDownloadLink = () => {
    onDownloadPdf(data.link);
  };

  const handleChangeType = (value: string) => {
    setProductType(value);
    onClick(value);
  };

  return (
    <Wrapper>
      <CardBox
        onClick={() => onClick(productType)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        isselected={String(isSelected)}
      >
        <CircleBox>
          <img
            src={getCircleImg()}
            alt=''
          />
        </CircleBox>
        <ImageBox
          component="img"
          src={getCardImage}
        />
        {/* <Box sx={{ margin: '8px 0' }}>
          <TextCardTitle>
            { data.title }
          </TextCardTitle>
        </Box> */}
        {/*{*/}
        {/*  data.description ?*/}
        {/*    <TextCardDescription>*/}
        {/*      { data.description }*/}
        {/*    </TextCardDescription>*/}
        {/*    : null*/}
        {/*}*/}
      </CardBox>

      <SwitchButtonsBox>
        <SwitchButtons
          currentValue={productType}
          onChange={handleChangeType}
          options={PRODUCT_TYPES}
          translation={true}
          buttonWidth={161}
        />
      </SwitchButtonsBox>

      <LinkBox>
        <AppLinkParagraph
          onClick={handleDownloadLink}
        >
          {t('product.pdf')}
        </AppLinkParagraph>
      </LinkBox>

    </Wrapper>
  );
};

export default ProduktCard;
