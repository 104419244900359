import {ReactElement} from 'react';
import {Box, IconButton, styled, SxProps} from "@mui/material";
import TextLabel from "../Typography/TextLabel";
import {FormInputLabelBox} from "./styles";
import svgTooltip from '../../../assets/img/tooltip.svg';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface IProps {
  label: string;
  tooltipAction?: () => void;
  children: ReactElement;
  sx?: SxProps;
}

const FormInput = ({ label, tooltipAction, children, sx }: IProps) => {
  return (
    <Wrapper
      sx={sx}
    >
      <FormInputLabelBox>
        <TextLabel>
          { label }
        </TextLabel>
        {
          tooltipAction
          ? <IconButton
              disableRipple
              onClick={tooltipAction}
              sx={{
                padding: '2px',
              }}
            >
              <img
                src={svgTooltip}
                alt='(i)'
              />
            </IconButton>
            : null
        }
      </FormInputLabelBox>
      { children }
    </Wrapper>
  );
};

export default FormInput;
