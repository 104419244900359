import {Box, Icon, styled, Typography} from "@mui/material";

export const CardLeftColumn = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface WrapperIcon {
  hover: string;
}

export const CardCircleIconRed = styled(Icon)<WrapperIcon>`
  rect {
    stroke: ${props => props.hover === 'true' ? '#E20031' : '#E0E0E0'};
  }
`;

export const CardCircleIconBlue = styled(Icon)<WrapperIcon>`
  rect {
    fill: ${props => props.hover === 'true' ? '#E20031' : '#06326E'};
  }
`;

export const CardCircleIconBlack = styled(Icon)<WrapperIcon>`
  rect {
    fill: ${props => props.hover === 'true' ? '#000' : '#06326E'};
  }
`;

interface WrapperPlus {
  disabled?: boolean;
}

export const CardPlus = styled(Icon)<WrapperPlus>`
  path {
    stroke: ${props => props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#06326E'};
  }
`;

export const CardTitle = styled(Typography)`
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Buttons = styled(Box)`
  display: flex;
`;

export const ButtonPart = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

export const CardIcon = styled(Box)`
  display: flex;
  width: 40px;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;